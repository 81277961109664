import React from 'react'
import MainMessengerView from '../../views/Messenger/MainMessengerView'

export default function MessengerPage(props) {
    return (
        <div>
            <MainMessengerView {...props}/>
        </div>
    )
}


