import ar from './ar';
import en from './en';
import fr from './fr';

export const dictionaryList = {
    en,
    fr,
};

export const languageOptions = [
    { id: 'fr', text: 'french' },
    { id: 'en', text: 'english'}
];