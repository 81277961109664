const sectors = [
    {value : 'agroalimentaire', label: 'filter.secteur.agroalimentaire'},
    {value : 'architecture', label: 'filter.secteur.architecture'},
    {value : 'art', label: 'filter.secteur.art'},
    {value : 'big_data', label: 'filter.secteur.big_data'},
    {value : 'bio', label: 'filter.secteur.bio'},
    {value : 'btp', label: 'filter.secteur.btp'},
    {value : 'commerce', label: 'filter.secteur.commerce'},
    {value : 'communication', label: 'filter.secteur.communication'},
    {value : 'design', label: 'filter.secteur.design'},
    {value : 'divertissement', label: 'filter.secteur.divertissement'},
    {value : 'droit', label: 'filter.secteur.droit'},
    {value : 'ecommerce', label: 'filter.secteur.ecommerce'},
    {value : 'education', label: 'filter.secteur.education'},
    {value : 'energie', label: 'filter.secteur.energie'},
    {value : 'environement', label: 'filter.secteur.environement'},
    {value : 'finance', label: 'filter.secteur.finance'},
    {value : 'information', label: 'filter.secteur.information'},
    {value : 'ia', label: 'filter.secteur.ia'},
    {value : 'internet_objets', label: 'filter.secteur.internet_objets'},
    {value : 'mode', label: 'filter.secteur.mode'},
    {value : 'robotique', label: 'filter.secteur.robotique'},
    {value : 'sante', label: 'filter.secteur.sante'},
    {value : 'villes_intelligences', label: 'filter.secteur.villes_intelligences'},
    {value : 'technologie', label: 'filter.secteur.technologie'},
    {value : 'transport', label: 'filter.secteur.transport'},
    {value : 'other', label: 'filter.secteur.other'}
];
export default sectors;