const cities_fr = {
    kabul: 'Kaboul',
    herat: 'Herat',
    mazar_i_sharif : 'Mazar-i-Sharif', 
    kandahar: 'Kandahar',
    jalalabad: 'Jalalabad',
    lashkargah: 'Lashkargah',
    kunduz: 'Kunduz',
    taloqan: 'Taloqan',
    puli_khumri: 'Puli Khumri',
    sheberghan: 'Sheberghan',
    zaranj: 'Zaranj',
    maimana: 'Maimana',
    ghazni: 'Ghazni',
    khost: 'Khost',
    charikar: 'Charikar',
    fayzabad: 'Fayzabad',
    tarinkot: 'Tarinkot',
    gardez: 'Gardez',

    mariehamn: 'Mariehamn',
    jomala: 'Jomala',
    finström: 'Finström',
    
    bajram_Curri: 'Bajram Curri',
    bajzë: 'Bajzë',
    ballsh: 'Ballsh',
    berat: 'Berat',
    bilisht: 'Bilisht',
    bulqizë: 'Bulqizë',
    burrel: 'Burrel',
    cërrik: 'Cërrik',
    çorovodë: 'Çorovodë',
    delvinë: 'Delvinë',
    divjakë: 'Divjakë',
    durrës: 'Durrës',
    elbasan: 'Elbasan',
    ersekë: 'Ersekë',
    fier: 'Fier',
    fierzë: 'Fierzë',
    finiq: 'Finiq',
    fushë_Arrëz: 'Fushë-Arrëz',
    fushë_Krujë: 'Fushë-Krujë',
    gjirokastër: 'Gjirokastër',
    gramsh: 'Gramsh',
    himarë: 'Himarë',
    kamëz: 'Kamëz',
    kavajë: 'Kavajë',
    kelcyrë: 'Kelcyrë',
    klos: 'Klos',
    konispol: 'Konispol',
    koplik: 'Koplik',
    korçë: 'Korçë',
    krashtë: 'Krashtë',
    krastë: 'Krastë',
    krrabë: 'Krrabë',
    krujë: 'Krujë',
    krumë: 'Krumë',
    kuçovë: 'Kuçovë',
    kukës: 'Kukës',
    kurbnesh: 'Kurbnesh',
    laç: 'Laç',
    leskovik: 'Leskovik',
    lezhë: 'Lezhë',
    libohovë: 'Libohovë',
    librazhd: 'Librazhd',
    lushnjë: 'Lushnjë',
    maliq: 'Maliq',
    mamurras: 'Mamurras',
    manëz: 'Manëz',
    memaliaj: 'Memaliaj',
    milot: 'Milot',
    orikum: 'Orikum',
    patos: 'Patos',
    peq: 'Peqin',
    përmet: 'Përmet',
    peshkopi: 'Peshkopi',
    pogradec: 'Pogradec',
    poliçan: 'Poliçan',
    prrenjas: 'Prrenjas',
    pukë: 'Pukë',
    reps: 'Reps',
    roskovec: 'Roskovec',
    rrëshen: 'Rrëshen',
    rrogozhinë: 'Rrogozhinë',
    rubik: 'Rubik',
    sarandë: 'Sarandë',
    selenicë: 'Selenicë',
    shëngjin: 'Shëngjin',
    shijak: 'Shijak',
    shkodër: 'Shkodër',
    sukth: 'Sukth',
    tepelenë: 'Tepelenë',
    tirana: 'Tirana',
    ulëz: 'Ulëz',
    ura_Vajgurore: 'Ura Vajgurore',
    vau_i_Dejës: 'Vau i Dejës',
    vlorë: 'Vlorë',
    vorë: 'Vorë',

    algiers: 'Alger',
    oran: 'Oran',
    constantine: 'Constantine',
    annaba: 'Annaba',
    blida: 'Blida',
    batna: 'Batna',
    djelfa: 'Djelfa',
    sétif: 'Sétif',
    sidi_bel_Abbès: 'Sidi bel Abbès',
    biskra: 'Biskra',
    tébessa: 'Tébessa',
    skikda: 'Skikda',
    tiaret: 'Tiaret',
    béjaïa: 'Béjaïa',
    tlemcen: 'Tlemcen',
    béchar: 'Béchar',
    mostaganem: 'Mostaganem',
    bordj_Bou_Arreridj: 'Bordj Bou Arreridj',
    chlef: 'Chlef',
    souk_Ahras: 'Souk Ahras',
    el_Eulma: 'El Eulma',
    médéa: 'Médéa',
    tizi_Ouzou: 'Tizi Ouzou',
    jijel: 'Jijel',
    laghouat: 'Laghouat',
    el_Oued: 'El Oued',
    ouargla: 'Ouargla',
    m_Sila: 'M\'Sila',
    relizane: 'Relizane',
    saïda: 'Saïda',
    bou_Saâda: 'Bou Saâda',
    guelma: 'Guelma',
    aïn_Beïda: 'Aïn Beïda',
    maghnia: 'Maghnia',
    mascara: 'Mascara',
    khenchela: 'Khenchela',
    barika: 'Barika',
    messaad: 'Messaad',
    aflou: 'Aflou',
    aïn_Oussara: 'Aïn Oussara',

    pago_Pago: 'Pago Pago',
    tāfuna: 'Tāfuna',
    leone: 'Leone',

    andorra_la_Vella: 'Andorra la Vella',
    escaldes_Engordany: 'Escaldes-Engordany',
    encamp: 'Encamp',

    luanda: 'Luanda',
    lubango: 'Lubango',
    huambo: 'Huambo',
    benguela: 'Benguela',
    cabinda: 'Cabinda',
    malanje: 'Malanje',
    saurimo: 'Saurimo',
    lobito: 'Lobito',
    cuito: 'Cuito',
    uíge: 'Uíge',
    luena: 'Luena',

    the_Valley: 'The Valley',
    blowing_Point_Village: 'Blowing Point Village',
    sandy_Ground_Village: 'Sandy Ground Village',

    saint_Johns: 'Saint Johns',
    all_Saints: 'All Saints',
    liberta: 'Liberta',

    buenos_Aires: 'Buenos Aires',
    córdoba: 'Córdoba',
    rosario: 'Rosario',
    la_Plata: 'La Plata',
    mar_del_Plata: 'Mar del Plata',
    san_Miguel_de_Tucumán: 'San Miguel de Tucumán',
    salta: 'Salta',
    santa_Fe_de_la_Vera_Cruz: 'Santa Fe de la Vera Cruz',
    vicente_López_Partido: 'Vicente López Partido',
    corrientes: 'Corrientes',

    yerevan: 'Yerevan',
    gyumri: 'Gyumri',
    vanadzor: 'Vanadzor',

    oranjestad: 'Oranjestad',
    san_Nicolaas: 'San Nicolaas',
    santa_Cruz: 'Santa Cruz',

    melbourne: 'Melbourne',
    sydney: 'Sydney',
    brisbane: 'Brisbane',
    perth: 'Perth',
    adelaide: 'Adelaide',
    gold_Coast_Tweed_Heads: 'Gold Coast–Tweed Heads',
    newcastle_Maitland: 'Newcastle–Maitland',
    canberra_Queanbeyan: 'Canberra–Queanbeyan',
    sunshine_Coast: 'Sunshine Coast',
    central_Coast: 'Central Coast',
    wollongong: 'Wollongong',
    geelong: 'Geelong',
    hobart: 'Hobart',
    townsville: 'Townsville',
    cairns: 'Cairns',
    toowoomba: 'Toowoomba',
    darwin: 'Darwin',
    ballarat: 'Ballarat',
    bendigo: 'Bendigo',
    albury_Wodonga: 'Albury-Wodonga',
    launceston: 'Launceston',
    mackay: 'Mackay',
    rockhampton: 'Rockhampton',
    bunbury: 'Bunbury',
    bundaberg: 'Bundaberg',
    coffs_Harbour: 'Coffs Harbour',
    hervey_Bay: 'Hervey Bay',
    wagga_Wagga: 'Wagga Wagga',
    shepparton_Mooroopna: 'Shepparton-Mooroopna',
    mildura_Buronga: 'Mildura-Buronga',
    port_Macquarie: 'Port Macquarie',
    gladstone: 'Gladstone',
    ballina: 'Ballina',
    warragul_Drouin: 'Warragul–Drouin',
    tamworth: 'Tamworth',
    busselton: 'Busselton',
    traralgon_Morwell: 'Traralgon–Morwell',
    orange: 'Orange',
    bowral_Mittagong: 'Bowral–Mittagong',
    dubbo: 'Dubbo',
    geraldton: 'Geraldton',
    nowra_Bomaderry: 'Nowra–Bomaderry',
    bathurst: 'Bathurst',
    albany: 'Albany',
    warrnambool: 'Warrnambool',
    devonport: 'Devonport',
    mount_Gambier: 'Mount Gambier',
    kalgoorlie_Boulder: 'Kalgoorlie–Boulder',
    victor_Harbor_Goolwa: 'Victor Harbor–Goolwa',
    morisset_Cooranbong: 'Morisset–Cooranbong',
    alice_Springs: 'Alice Springs',
    nelson_Bay: 'Nelson Bay',
    burnie_Somerset: 'Burnie–Somerset',
    maryborough: 'Maryborough',
    lismore: 'Lismore',
    taree: 'Taree',
    bacchus_Marsh: 'Bacchus Marsh',
    goulburn: 'Goulburn',
    armidale: 'Armidale',
    gympie: 'Gympie',
    gisborne: 'Gisborne',
    echuca_Moama: 'Echuca–Moama',
    moe_Newborough: 'Moe–Newborough',
    whyalla: 'Whyalla',
    yeppoon: 'Yeppoon',
    forster_Tuncurry: 'Forster–Tuncurry',
    griffith: 'Griffith',
    st_Georges_Basin_Sanctuary_Point: 'St Georges Basin–Sanctuary Point',
    wangaratta: 'Wangaratta',
    grafton: 'Grafton',
    murray_Bridge: 'Murray Bridge',
    camden_Haven: 'Camden Haven',
    karratha: 'Karratha',
    mount_Isa: 'Mount Isa',
    batemans_Bay: 'Batemans Bay',
    broken_Hill: 'Broken Hill',
    singleton: 'Singleton',
    ulladulla: 'Ulladulla',
    port_Lincoln: 'Port Lincoln',
    horsham: 'Horsham',
    port_Hedland: 'Port Hedland',
    kempsey: 'Kempsey',
    warwick: 'Warwick',
    medowie: 'Medowie',
    broome: 'Broome',
    bairnsdale: 'Bairnsdale',
    airlie_Beach_Cannonvale: 'Airlie Beach-Cannonvale',
    ulverstone: 'Ulverstone',
    sale: 'Sale',
    emerald: 'Emerald',
    port_Pirie: 'Port Pirie',
    port_Augusta: 'Port Augusta',
    colac: 'Colac',
    muswellbrook: 'Muswellbrook',
    esperance: 'Esperance',
    mudgee: 'Mudgee',
    lithgow: 'Lithgow',
    castlemaine: 'Castlemaine',
    portland: 'Portland',
    byron_Bay: 'Byron Bay',
    swan_Hill: 'Swan Hill',
    kingaroy: 'Kingaroy',

    vienna: 'Vienne',
    graz: 'Graz',
    linz: 'Linz',
    salzburg: 'Salzbourg',
    innsbruck: 'Innsbruck',
    klagenfurt_am_Wörthersee: 'Klagenfurt am Wörthersee',
    villach: 'Villach',
    wels: 'Wels',
    sankt_Pölten: 'Sankt Pölten',
    dornbirn: 'Dornbirn',
    wiener_Neustadt: 'Wiener Neustadt',

    baki: 'Bakou',
    sumqayit: 'Sumqayit',
    gəncə: 'Gəncə',

    nassau: 'Nassau',
    lucaya: 'Lucaya',
    freeport: 'Freeport',

    manama: 'Manama',
    riffa: 'Riffa',
    muharraq: 'Muharraq',

    dhaka: 'Dacca',
    chittagong: 'Chittagong',
    khulna: 'Khulna',

    bridgetown: 'Bridgetown',
    speightstown: 'Speightstown',
    oistins: 'Oistins',

    minsk: 'Minsk',
    homyel: 'Homyel',
    hrodna: 'Hrodna',

    aalst: 'Aalst',
    aarschot: 'Aarschot',
    andenne: 'Andenne',
    antoing: 'Antoing',
    ans: 'Ans',
    antwerp: 'Anvers',
    arlon: 'Arlon',
    ath: 'Ath',
    aubange: 'Aubange',
    bastogne: 'Bastogne',
    beaumont: 'Beaumont',
    beauraing: 'Beauraing',
    beringen: 'Beringen',
    bilzen: 'Bilzen',
    binche: 'Binche',
    blankenberge: 'Blankenberge',
    borgloon: 'Borgloon',
    bouillon: 'Bouillon',
    braine_le_Comte: 'Braine-le-Comte',
    bree: 'Bree',
    bruges: 'Bruges',
    brussels: 'Bruxelles',
    charleroi: 'Charleroi',
    châtelet: 'Châtelet',
    chièvres: 'Chièvres',
    chimay: 'Chimay',
    chiny: 'Chiny',
    ciney: 'Ciney',
    comines_Warneton: 'Comines-Warneton',
    couvin: 'Couvin',
    damme: 'Damme',
    deinze: 'Deinze',
    dendermonde: 'Dendermonde',
    diest: 'Diest',
    diksmuide: 'Diksmuide',
    dilsen_Stokkem: 'Dilsen-Stokkem',
    dinant: 'Dinant',
    durbuy: 'Durbuy',
    eeklo: 'Eeklo',
    enghien: 'Enghien',
    eupen: 'Eupen',
    fleurus: 'Fleurus',
    florenville: 'Florenville',
    fontaine_l_Évêque: 'Fontaine-l\'Évêque',
    fosses_la_Ville: 'Fosses-la-Ville',
    geel: 'Geel',
    gembloux: 'Gembloux',
    genappe: 'Genappe',
    genk: 'Genk',
    geraardsbergen: 'Geraardsbergen',
    ghent: 'Gand',
    gistel: 'Gistel',
    halen: 'Halen',
    halle: 'Halle',
    hamont_Achel: 'Hamont-Achel',
    hannut: 'Hannut',
    harelbeke: 'Harelbeke',
    hasselt: 'Hasselt',
    herk_de_Stad: 'Herk-de-Stad',
    herentals: 'Herentals',
    herstal: 'Herstal',
    herve: 'Herve',
    hoogstraten: 'Hoogstraten',
    houffalize: 'Houffalize',
    huy: 'Huy',
    izegem: 'Izegem',
    kortrijk: 'Courtrai',
    jodoigne: 'Jodoigne',
    la_Louvière: 'La Louvière',
    la_Roche_en_Ardenne: 'La Roche-en-Ardenne',
    landen: 'Landen',
    le_Rœulx: 'Le Rœulx',
    lessines: 'Lessines',
    leuven: 'Louvain',
    leuze_en_Hainaut: 'Leuze-en-Hainaut',
    liège: 'Liège',
    lier: 'Lierre',
    limbourg: 'Limbourg',
    lokeren: 'Lokeren',
    lommel: 'Lommel',
    lo_Reninge: 'Lo-Reninge',
    maaseik: 'Maaseik',
    malmedy: 'Malmedy',
    marche_en_Famenne: 'Marche-en-Famenne',
    mechelen: 'Malines',
    menen: 'Menin',
    mesen: 'Mesen',
    mons: 'Mons',
    mortsel: 'Mortsel',
    mouscron: 'Mouscron',
    namur: 'Namur',
    neufchâteau: 'Neufchâteau',
    nieuwpoort: 'Nieuport',
    ninove: 'Ninove',
    nivelles: 'Nivelles',
    ostend: 'Ostende',
    ottignies_Louvain_la_Neuve: 'Ottignies-Louvain-la-Neuve',
    oudenaarde: 'Audenaerde',
    oudenburg: 'Oudenburg',
    peer: 'Peer',
    péruwelz: 'Péruwelz',
    philippeville: 'Philippeville',
    poperinge: 'Poperinge',
    rochefort: 'Rochefort',
    roeselare: 'Roulers',
    ronse: 'Renaix',
    saint_Ghislain: 'Saint-Ghislain',
    saint_Hubert: 'Saint-Hubert',
    st_Vith: 'Saint-Vith',
    scherpenheuvel_Zichem: 'Scherpenheuvel-Zichem',
    seraing: 'Seraing',
    sint_Niklaas: 'Saint-Nicolas',
    sint_Truiden: 'Saint-Trond',
    soignies: 'Soignies',
    spa: 'Spa',
    stavelot: 'Stavelot',
    thuin: 'Thuin',
    tienen: 'Tirlemont',
    tongeren: 'Tongres',
    torhout: 'Torhout',
    tournai: 'Tournai',
    tubize: 'Tubize',
    turnhout: 'Turnhout',
    verviers: 'Verviers',
    veurne: 'Furnes',
    vilvoorde: 'Vilvorde',
    virton: 'Virton',
    visé: 'Visé',
    walcourt: 'Walcourt',
    waregem: 'Waregem',
    waremme: 'Waremme',
    wavre: 'Wavre',
    wervik: 'Wervicq',
    ypres: 'Ypres',
    zottegem: 'Zottegem',
    zoutleeuw: 'Zoutleeuw',

    belize_City: 'Belize City',
    san_Ignacio: 'San Ignacio',
    san_Pedro_Town: 'San Pedro Town',

    abomey: 'Abomey',
    abomey_Calavi: 'Abomey-Calavi',
    athiémè: 'Athiémè',
    banikoara: 'Banikoara',
    bassila: 'Bassila',
    bembèrèkè: 'Bembèrèkè',
    bétérou: 'Bétérou',
    bohicon: 'Bohicon',
    bori: 'Bori',
    boukoumbé: 'Boukoumbé',
    comè: 'Comè',
    cotonou: 'Cotonou',
    cové: 'Cové',
    dassa_Zoumé: 'Dassa-Zoumé',
    djougou: 'Djougou',
    dogbo_Tota: 'Dogbo-Tota',
    founougo: 'Founougo',
    ganvie: 'Ganvie',
    godomey: 'Godomey',
    grand_Popo: 'Grand-Popo',
    guénè: 'Guénè',
    kandi: 'Kandi',
    kérou: 'Kérou',
    kétou: 'Kétou',
    kouandé: 'Kouandé',
    lokossa: 'Lokossa',
    malanville: 'Malanville',
    natitingou: 'Natitingou',
    ndali: 'Ndali',
    nikki: 'Nikki',
    ouidah: 'Ouidah',
    parakou: 'Parakou',
    péhonko: 'Péhonko',
    pobè: 'Pobè',
    porga: 'Porga',
    sakété: 'Sakété',
    sam: 'Sam',
    savalou: 'Savalou',
    savé: 'Savé',
    ségbana: 'Ségbana',
    tanguiéta: 'Tanguiéta',
    tchaourou: 'Tchaourou',
    toura: 'Toura',

    saint_George: 'Saint George',
    hamilton: 'Hamilton',
    stovel_Bay: 'Stovel Bay',

    thimphu: 'Thimphu',
    phuntsholing: 'Phuntsholing',
    paro: 'Paro',

    santa_Cruz_de_la_Sierra: 'Santa Cruz de la Sierra',
    la_Paz: 'La Paz',
    el_Alto: 'El Alto',

    kralendijk: 'Kralendijk',
    dorp_Antriol: 'Dorp Antriol',
    dorp_Rincon: 'Dorp Rincon',

    sarajevo: 'Sarajevo',
    banja_Luka: 'Banja Luka',
    tuzla: 'Tuzla',

    gaborone: 'Gaborone',
    francistown: 'Francistown',
    mogoditshane: 'Mogoditshane',
    maun: 'Maun',
    molepolole: 'Molepolole',
    serowe: 'Serowe',
    tlokweng: 'Tlokweng',
    palapye: 'Palapye',
    mochudi: 'Mochudi',
    mahalapye: 'Mahalapye',
    kanye: 'Kanye',
    selibe_Phikwe: 'Selibe Phikwe',
    letlhakane: 'Letlhakane',
    ramotswa: 'Ramotswa',
    lobatse: 'Lobatse',
    mmopane: 'Mmopane',
    thamaga: 'Thamaga',
    moshupa: 'Moshupa',
    tonota: 'Tonota',
    bobonong: 'Bobonong',
    gabane: 'Gabane',
    ghanzi: 'Ghanzi',
    jwaneng: 'Jwaneng',
    tutume: 'Tutume',
    kopong: 'Kopong',
    mmadinare: 'Mmadinare',
    tati_Siding: 'Tati Siding',
    tshabong: 'Tshabong',
    metsimotlhabe: 'Metsimotlhabe',
    gumare: 'Gumare',
    shakawe: 'Shakawe',
    oodi: 'Oodi',
    bokaa: 'Bokaa',
    kasane: 'Kasane',
    borolong: 'Borolong',
    shoshong: 'Shoshong',
    molapowabojang: 'Molapowabojang',
    kazungula: 'Kazungula',
    orapa: 'Orapa',
    lethlakeng: 'Lethlakeng',
    nata: 'Nata',
    rakops: 'Rakops',
    mmankgodi: 'Mmankgodi',
    lerala: 'Lerala',
    otse: 'Otse',
    kumakwane: 'Kumakwane',
    kang: 'Kang',
    lentsweletau: 'Lentsweletau',
    ramotswa_station_Taung: 'Ramotswa station/Taung',
    masunga: 'Masunga',
    lotlhakane: 'Lotlhakane',
    sefophe: 'Sefophe',
    rasesa: 'Rasesa',
    maitengwe: 'Maitengwe',
    mathangwane: 'Mathangwane',
    shashe_Mooke: 'Shashe-Mooke',
    good_Hope: 'Good Hope',
    gweta: 'Gweta',
    mmathethe: 'Mmathethe',
    sefhare: 'Sefhare',
    chadibe: 'Chadibe',
    tsetsebjwe: 'Tsetsebjwe',
    hukuntsi: 'Hukuntsi',
    charles_Hill: 'Charles Hill',
    morwa: 'Morwa',
    mandunyane: 'Mandunyane',
    etsha_6: 'Etsha 6',
    digawana: 'Digawana',
    mokatse: 'Mokatse',
    pitsane_Siding: 'Pitsane Siding',
    mopipi: 'Mopipi',
    dukwi: 'Dukwi',
    nkange: 'Nkange',
    matshelagabedi: 'Matshelagabedi',
    ramokgonami: 'Ramokgonami',
    sebina: 'Sebina',
    maunatlala: 'Maunatlala',
    shashe_Mooke: 'Shashe-Mooke',
    manyana: 'Manyana',
    chadibe: 'Chadibe',
    modipane: 'Modipane',
    takatokwane: 'Takatokwane',
    etsha_13: 'Etsha 13',
    lecheng: 'Lecheng',
    werda: 'Werda',
    moiyabana: 'Moiyabana',
    gakuto: 'Gakuto',
    pilane: 'Pilane',
    ranaka: 'Ranaka',
    matebeleng: 'Matebeleng',
    khudumelapye: 'Khudumelapye',
    khakhea: 'Khakhea',
    serule: 'Serule',
    matlapana: 'Matlapana',
    mogorosi: 'Mogorosi',
    tobane: 'Tobane',
    mookane: 'Mookane',
    mmashoro: 'Mmashoro',
    mogobane: 'Mogobane',
    sowa: 'Sowa',
    thabala: 'Thabala',
    dekar: 'Dekar',
    malolwane: 'Malolwane',
    seronga: 'Seronga',
    macheneng: 'Macheneng',
    pandamatenga: 'Pandamatenga',
    paje: 'Paje',
    molalatau: 'Molalatau',
    sehitwa: 'Sehitwa',
    tumasera: 'Tumasera',
    ditshegwane: 'Ditshegwane',
    salajwe: 'Salajwe',
    mokoboxane: 'Mokoboxane',
    mathathane: 'Mathathane',
    mmathubudukwane: 'Mmathubudukwane',
    nokaneng: 'Nokaneng',
    mabutsane: 'Mabutsane',
    marapong: 'Marapong',
    nshakazhokwe: 'Nshakazhokwe',
    senete: 'Senete',
    artisia: 'Artisia',
    sojwe: 'Sojwe',
    mabelepudi: 'Mabelepudi',
    lesenepole: 'Lesenepole',
    mosolotshane: 'Mosolotshane',
    tshesebe: 'Tshesebe',
    matsiloje: 'Matsiloje',
    otse: 'Otse',
    kalamare: 'Kalamare',
    ratholo: 'Ratholo',
    mokobeng: 'Mokobeng',
    ncojane: 'Ncojane',
    nswazwi: 'Nswazwi',
    lorolwana: 'Lorolwana',
    mogapi: 'Mogapi',
    makobo: 'Makobo',
    tsamaya: 'Tsamaya',
    tsau: 'Tsau',
    mmatsata: 'Mmatsata',
    seleka: 'Seleka',
    maokane: 'Maokane',
    sepopa: 'Sepopa',
    mabesekwa: 'Mabesekwa',
    moetlamane: 'Moetlamane',
    tshimoyapula: 'Tshimoyapula',
    mosetse: 'Mosetse',
    lotlhakane_West: 'Lotlhakane West',
    mokubilo: 'Mokubilo',
    xhumo: 'Xhumo',
    lehututu: 'Lehututu',
    sesung: 'Sesung',
    moroka: 'Moroka',
    gobojiango: 'Gobojiango',
    rakhuna: 'Rakhuna',
    marobela: 'Marobela',
    moshaneng: 'Moshaneng',
    themaashanga: 'Themaashanga',
    tsootsha: 'Tsootsha',
    gojwane: 'Gojwane',
    kauxwhi: 'Kauxwhi',
    kudumatse: 'Kudumatse',
    malatswai: 'Malatswai',
    mmanoko: 'Mmanoko',
    toteng: 'Toteng',
    mabule: 'Mabule',
    maboane: 'Maboane',
    makawate: 'Makawate',
    lokwabe: 'Lokwabe',
    motlhabaneng: 'Motlhabaneng',
    dimajwe: 'Dimajwe',
    makopong: 'Makopong',
    majwaneng: 'Majwaneng',
    zweenshambe: 'Zweenshambe',
    ditladi: 'Ditladi',
    mogapinyana: 'Mogapinyana',
    magothlwane: 'Magothlwane',
    selokolela: 'Selokolela',
    lorwana: 'Lorwana',
    motokwe: 'Motokwe',
    mmatshumo: 'Mmatshumo',
    moshaweng: 'Moshaweng',
    mosu: 'Mosu',
    kokotsha: 'Kokotsha',
    kgomokasitwa: 'Kgomokasitwa',
    samochema: 'Samochema',
    goshwe: 'Goshwe',
    natale: 'Natale',
    phitshane_Molopo: 'Phitshane Molopo',
    new_Xade: 'New Xade',
    gamodubu: 'Gamodubu',
    topisi: 'Topisi',
    seolwane: 'Seolwane',
    xakao: 'Xakao',
    kagagodi: 'Kagagodi',
    zoroga: 'Zoroga',
    makalamabedi: 'Makalamabedi',
    kodibeleng: 'Kodibeleng',
    ramatlabama: 'Ramatlabama',
    tshokwe: 'Tshokwe',
    mafongo: 'Mafongo',
    sikwane: 'Sikwane',
    ramokgwabana: 'Ramokgwabana',
    kedia: 'Kedia',
    etsha_1: 'Etsha 1',
    matobo: 'Matobo',
    nlakwane: 'Nlakwane',
    damochojena: 'Damochojena',
    pitseng: 'Pitseng',
    khwee: 'Khwee',
    matsitama: 'Matsitama',
    beetsha: 'Beetsha',
    nxamasere: 'Nxamasere',
    semolale: 'Semolale',
    bothapatlou: 'Bothapatlou',
    senyame: 'Senyame',
    sekoma: 'Sekoma',
    dutlwe: 'Dutlwe',
    makaleng: 'Makaleng',
    mapoka: 'Mapoka',
    gootau: 'Gootau',
    tamasane: 'Tamasane',
    malwelwe: 'Malwelwe',
    phepheng: 'Phepheng',
    boatalname: 'Boatalname',
    dibete_Station: 'Dibete Station',
    kutamogree: 'Kutamogree',
    dinokwe: 'Dinokwe',
    borotsi: 'Borotsi',
    jackalas_2: 'Jackalas 2',
    sorilatholo: 'Sorilatholo',
    siviya: 'Siviya',
    kareng: 'Kareng',
    jackalas_1: 'Jackalas 1',
    pilikwe: 'Pilikwe',
    mmaphashalala: 'Mmaphashalala',
    ngarange: 'Ngarange',
    lekgolobotlo: 'Lekgolobotlo',
    tloaneng: 'Tloaneng',
    hatsalatladi: 'Hatsalatladi',
    maape: 'Maape',
    moralane: 'Moralane',
    khawa: 'Khawa',
    bray: 'Bray',
    kaudwane: 'Kaudwane',
    sehunou: 'Sehunou',
    shakwe: 'Shakwe',
    motopi: 'Motopi',
    sekhutlane: 'Sekhutlane',
    omaweneno: 'Omaweneno',
    mokolodi: 'Mokolodi',
    motshegaletau: 'Motshegaletau',
    lesoma: 'Lesoma',
    mabalane: 'Mabalane',
    kuke: 'Kuke',
    shashe_Bridge: 'Shashe Bridge',
    keng: 'Keng',
    kachikau: 'Kachikau',
    groote_Laagte: 'Groote Laagte',
    serinane: 'Serinane',
    matlhakola: 'Matlhakola',
    moshopha: 'Moshopha',
    gasita: 'Gasita',
    mahotshwane: 'Mahotshwane',
    mulambakwena: 'Mulambakwena',
    tlhankane: 'Tlhankane',
    sepako: 'Sepako',
    mosojane: 'Mosojane',
    kokong: 'Kokong',
    leologane: 'Leologane',
    ngware: 'Ngware',
    gani: 'Gani',
    malaka: 'Malaka',
    west_Hanahai: 'West Hanahai',
    gathwane: 'Gathwane',
    karakobis: 'Karakobis',
    tshane: 'Tshane',
    borotsi: 'Borotsi',
    bodibeng: 'Bodibeng',
    mogonye: 'Mogonye',
    jamataka: 'Jamataka',
    mogomotho: 'Mogomotho',
    mokhomba: 'Mokhomba',
    lepokole: 'Lepokole',
    dagwi: 'Dagwi',
    magoriapitse: 'Magoriapitse',
    mmeya: 'Mmeya',
    qabo: 'Qabo',
    metlobo: 'Metlobo',
    malotwana_Siding: 'Malotwana Siding',
    shorobe: 'Shorobe',
    qangwa: 'Qangwa',
    kumaga: 'Kumaga',
    pitsana_Potokwe: 'Pitsana-Potokwe',
    segwagwa: 'Segwagwa',
    dovedale: 'Dovedale',
    chobokwane: 'Chobokwane',
    parakarungu: 'Parakarungu',
    mmadikola: 'Mmadikola',
    mokgenene: 'Mokgenene',

    são_Paulo: 'São Paulo',
    rio_de_Janeiro: 'Rio de Janeiro',
    brasília: 'Brasília',
    fortaleza: 'Fortaleza',
    salvador: 'Salvador',
    belo_Horizonte: 'Belo Horizonte',
    manaus: 'Manaus',
    curitiba: 'Curitiba',
    recife: 'Recife',
    goiânia: 'Goiânia',

    bandar_Seri_Begawan: 'Bandar Seri Begawan',
    kuala_Belait: 'Kuala Belait',
    seria: 'Seria',

    sofia: 'Sofia',
    plovdiv: 'Plovdiv',
    varna: 'Varna',
    burgas: 'Burgas',
    ruse: 'Ruse',
    stara_Zagora: 'Stara Zagora',
    pleven: 'Pleven',
    sliven: 'Sliven',
    dobrich: 'Dobrich',
    shumen: 'Shumen',

    ouagadougou: 'Ouagadougou',
    bobo_Dioulasso: 'Bobo-Dioulasso',
    koudougou: 'Koudougou',
    ouahigouya: 'Ouahigouya',
    kaya: 'Kaya',
    banfora: 'Banfora',
    pouytenga: 'Pouytenga',
    houndé: 'Houndé',
    fada_N_Gourma: 'Fada N\'Gourma',
    dédougou: 'Dédougou',
    djibo: 'Djibo',
    tenkodogo: 'Tenkodogo',
    kongoussi: 'Kongoussi',
    léo: 'Léo',
    koupéla: 'Koupéla',
    titao: 'Titao',
    dori: 'Dori',
    gaoua: 'Gaoua',
    garango: 'Garango',
    gourcy: 'Gourcy',
    yako: 'Yako',
    zorgho: 'Zorgho',
    réo: 'Réo',
    orodara: 'Orodara',
    ziniaré: 'Ziniaré',
    niangoloko: 'Niangoloko',
    nouna: 'Nouna',
    bittou: 'Bittou',
    kombissiri: 'Kombissiri',
    manga: 'Manga',

    bujumbura: 'Bujumbura',
    gitega: 'Gitega',
    muyinga: 'Muyinga',
    ruyigi: 'Ruyigi',
    ngozi: 'Ngozi',
    rutana: 'Rutana',
    bururi: 'Bururi',
    makamba: 'Makamba',
    kayanza: 'Kayanza',
    muramvya: 'Muramvya',

    praia: 'Praia',
    mindelo: 'Mindelo',
    espargos: 'Espargos',

    phnom_Penh: 'Phnom Penh',
    takeo: 'Takeo',
    siem_Reap: 'Siem Reap',

    abong_Mbang: 'Abong-Mbang',
    ako: 'Ako',
    akonolinga: 'Akonolinga',
    ambam: 'Ambam',
    bafang: 'Bafang',
    bafia: 'Bafia',
    bafoussam: 'Bafoussam',
    bafut: 'Bafut',
    bali: 'Bali',
    bambili: 'Bambili',
    bamenda: 'Bamenda',
    batibo: 'Batibo',
    bandjoun: 'Bandjoun',
    bangem: 'Bangem',
    banyo: 'Banyo',
    batouri: 'Batouri',
    bélabo: 'Bélabo',
    bertoua: 'Bertoua',
    buea: 'Buea',
    campo: 'Campo',
    dimako: 'Dimako',
    dizangue: 'Dizangue',
    djoum: 'Djoum',
    douala: 'Douala',
    dschang: 'Dschang',
    ebolowa: 'Ebolowa',
    edéa: 'Edéa',
    eyumojock: 'Eyumojock',
    fontem: 'Fontem',
    foumban: 'Foumban',
    foumbot: 'Foumbot',
    fundong: 'Fundong',
    garoua: 'Garoua',
    garoua_Boulaï: 'Garoua-Boulaï',
    guider: 'Guider',
    idenau: 'Idenau',
    kaélé: 'Kaélé',
    kousséri: 'Kousséri',
    kribi: 'Kribi',
    kumba: 'Kumba',
    kumbo: 'Kumbo',
    limbé: 'Limbe',
    lomié: 'Lomié',
    loum: 'Loum',
    makénéné: 'Makénéné',
    mamfe: 'Mamfe',
    manjo: 'Manjo',
    maroua: 'Maroua',
    martap: 'Martap',
    mbalmayo: 'Mbalmayo',
    mbandjock: 'Mbandjock',
    mbanga: 'Mbanga',
    mbê: 'Mbê',
    mbengwi: 'Mbengwi',
    mbouda: 'Mbouda',
    meiganga: 'Meiganga',
    melong: 'Melong',
    menji: 'Menji',
    muyuka: 'Muyuka',
    nkoteng: 'Nkoteng',
    mokolo: 'Mokolo',
    monatélé: 'Monatélé',
    mora: 'Mora',
    mouloudou: 'Mouloudou',
    mutengene: 'Mutengene',
    nanga_Eboko: 'Nanga Eboko',
    ndu: 'Ndu',
    ndop: 'Ndop',
    ngaoundéré: 'Ngaoundéré',
    nkambe: 'Nkambe',
    nkongsamba: 'Nkongsamba',
    nkwen: 'Nkwen',
    obala: 'Obala',
    penja: 'Penja',
    sa_a: 'Sa\'a',
    sangmélima: 'Sangmélima',
    santa: 'Santa',
    tibati: 'Tibati',
    tiko: 'Tiko',
    wum: 'Wum',
    yaoundé: 'Yaoundé',
    yagoua: 'Yagoua',
    yokadouma: 'Yokadouma',

    toronto: 'Toronto',
    montreal: 'Montréal',
    vancouver: 'Vancouver',
    calgary: 'Calgary',
    ottawa: 'Ottawa',
    edmonton: 'Edmonton',
    winnipeg: 'Winnipeg',
    mississauga: 'Mississauga',
    brampton: 'Brampton',
    hamilton: 'Hamilton',
    surrey: 'Surrey',
    quebec_City: 'Québec',
    halifax: 'Halifax',
    laval: 'Laval',

    george_Town: 'George Town',
    west_Bay: 'West Bay',
    bodden_Town: 'Bodden Town',

    bangui: 'Bangui',
    bimbo: 'Bimbo',
    bégoua: 'Bégoua',

    nDjamena: 'N\'Djamena',
    moundou: 'Moundou',
    sarh: 'Sarh',

    santiago: 'Santiago',
    valparaíso: 'Valparaíso',
    concepción: 'Concepción',

    shanghai: 'Shanghai',
    beijing: 'Pékin',
    guangzhou: 'Guangzhou',

    drumsite: 'Drumsite',
    flying_Fish_Cove: 'Flying Fish Cove',
    poon_Saan: 'Poon Saan',

    bogotá: 'Bogotá',
    medellín: 'Medellín',
    cali: 'Cali',

    moroni: 'Moroni',
    moutsamoudou: 'Moutsamoudou',
    fomboni: 'Fomboni',

    brazzaville: 'Brazzaville',
    pointe_Noire: 'Pointe-Noire',
    dolisie: 'Dolisie',

    kinshasa: 'Kinshasa',
    lubumbashi: 'Lubumbashi',
    mbuji_Mayi: 'Mbuji-Mayi',

    avarua: 'Avarua',
    amuri: 'Amuri',
    omoka: 'Omoka',
    
    san_José: 'San José',
    heredia: 'Heredia',
    cartago: 'Cartago',
    
    zagreb: 'Zagreb',
    split: 'Split',
    rijeka: 'Rijeka',
    
    havana: 'La Havane',
    santiago_de_Cuba: 'Santiago de Cuba',
    camagüey: 'Camagüey',
    
    willemstad: 'Willemstad',
    sint_Michiel: 'Sint Michiel',
    barber: 'Barber',
    
    nicosia: 'Nicosie',
    limassol: 'Limassol',
    larnaca: 'Larnaca',
    
    prague: 'Prague',
    brno: 'Brno',
    ostrava: 'Ostrava',
    
    abidjan: 'Abidjan',
    bouaké: 'Bouaké',
    korhogo: 'Korhogo',
    daloa: 'Daloa',
    san_Pédro: 'San-Pédro',
    yamoussoukro: 'Yamoussoukro',
    
    copenhagen: 'Copenhague',
    aarhus: 'Aarhus',
    odense: 'Odense',
    
    djibouti: 'Djibouti',
    ali_Sabieh: 'Ali Sabieh',
    dikhil: 'Dikhil',
    
    roseau: 'Roseau',
    portsmouth: 'Portsmouth',
    marigot: 'Marigot',
    
    santo_Domingo: 'Saint-Domingue',
    santiago_de_los_Caballeros: 'Santiago de los Caballeros',
    santo_Domingo_Oeste: 'Santo Domingo Oeste',
    
    guayaquil: 'Guayaquil',
    quito: 'Quito',
    cuenca: 'Cuenca',
    
    cairo: 'Le Caire',
    alexandria: 'Alexandrie',
    gizeh: 'Gizeh',
    shubra_El_Kheima: 'Shubra El-Kheima',
    port_Said: 'Port Saïd',
    suez: 'Suez',
    luxor: 'Louxor',
    al_Mansura: 'Al-Mansura',
    el_Mahalla_El_Kubra: 'El-Mahalla El-Kubra',
    tanta: 'Tanta',
    
    san_Salvador: 'San Salvador',
    soyapango: 'Soyapango',
    san_Miguel: 'San Miguel',
    
    bata: 'Bata',
    malabo: 'Malabo',
    ebebiyin: 'Ebebiyin',
    
    asmara: 'Asmara',
    keren: 'Keren',
    himora: 'Himora',
    
    tallinn: 'Tallinn',
    tartu: 'Tartu',
    narva: 'Narva',
    pärnu: 'Pärnu',
    kohtla_Järve: 'Kohtla-Järve',
    viljandi: 'Viljandi',
    rakvere: 'Rakvere',
    maardu: 'Maardu',
    kuressaare: 'Kuressaare',
    sillamäe: 'Sillamäe',
    
    manzini: 'Manzini',
    mbabane: 'Mbabane',
    big_Bend: 'Big Bend',
    
    addis_Ababa: 'Addis-Abeba',
    dire_Dawa: 'Dire Dawa',
    mekelle: 'Mekelle',
    adama: 'Adama',
    awassa: 'Awassa',
    bahir_Dar: 'Bahir Dar',
    gonder: 'Gonder',
    dessie: 'Dessie',
    jimma: 'Jimma',
    jijiga: 'Jijiga',
    
    stanley: 'Stanley',
    goose_Green_Settlement: 'Goose Green Settlement',
    port_Howard: 'Port Howard',
    
    torshavn: 'Tórshavn',
    klaksvik: 'Klaksvík',
    hoyvik: 'Hoyvík',
    
    nasinu: 'Nasinu',
    suva: 'Suva',
    lautoka: 'Lautoka',
    
    helsinki: 'Helsinki',
    tampere: 'Tampere',
    turku: 'Turku',
    oulu: 'Oulu',
    jyväskylä: 'Jyväskylä',
    lahti: 'Lahti',
    kuopio: 'Kuopio',
    pori: 'Pori',
    joensuu: 'Joensuu',
    vaasa: 'Vaasa',
    
    paris: 'Paris',
    marseille: 'Marseille',
    lyon: 'Lyon',
    toulouse: 'Toulouse',
    nice: 'Nice',
    nantes: 'Nantes',
    strasbourg: 'Strasbourg',
    montpellier: 'Montpellier',
    bordeaux: 'Bordeaux',
    lille: 'Lille',
    rennes: 'Rennes',
    reims: 'Reims',
    le_Havre: 'Le Havre',
    saint_Étienne: 'Saint-Étienne',
    toulon: 'Toulon',
    grenoble: 'Grenoble',
    dijon: 'Dijon',
    nîmes: 'Nîmes',
    angers: 'Angers',
    villeurbanne: 'Villeurbanne',
    le_Mans: 'Le Mans',
    saint_Denis: 'Saint-Denis',
    aix_en_Provence: 'Aix-en-Provence',
    clermont_Ferrand: 'Clermont-Ferrand',
    brest: 'Brest',
    limoges: 'Limoges',
    tours: 'Tours',
    amiens: 'Amiens',
    perpignan: 'Perpignan',
    metz: 'Metz',
    besançon: 'Besançon',
    boulogne_Billancourt: 'Boulogne-Billancourt',
    orléans: 'Orléans',
    rouen: 'Rouen',
    caen: 'Caen',
    argenteuil: 'Argenteuil',
    montreuil: 'Montreuil',
    nancy: 'Nancy',
    roubaix: 'Roubaix',
    nanterre: 'Nanterre',
    avignon: 'Avignon',
    créteil: 'Créteil',
    dunkirk: 'Dunkerque',
    poitiers: 'Poitiers',
    pau: 'Pau',
    la_Rochelle: 'La Rochelle',
    cannes: 'Cannes',
    calais: 'Calais',
    ajaccio: 'Ajaccio',
    neuilly_sur_Seine: 'Neuilly-sur-Seine',
    valence: 'Valence',
    cergy: 'Cergy',
    troyes: 'Troyes',
    clichy: 'Clichy',
    ivry_sur_Seine: 'Ivry-sur-Seine',
    lorient: 'Lorient',
    sarcelles: 'Sarcelles',
    beauvais: 'Beauvais',
    évry: 'Évry',
    annecy: 'Annecy',
    belfort: 'Belfort',
    
    cayenne: 'Cayenne',
    matoury: 'Matoury',
    saint_Laurent_du_Maroni: 'Saint-Laurent-du-Maroni',
    
    faaa: 'Faaa',
    papeete: 'Papeete',
    punaauia: 'Punaauia',
    
    libreville: 'Libreville',
    port_Gentil: 'Port-Gentil',
    pointe_Denis: 'Pointe Denis',
    
    banjul: 'Banjul',
    brikama: 'Brikama',
    sukuta: 'Sukuta',
    
    tbilisi: 'Tbilissi',
    batumi: 'Batoumi',
    kutaisi: 'Koutaïssi',
    
    berlin: 'Berlin',
    hamburg: 'Hambourg',
    munich: 'Munich',
    cologne: 'Cologne',
    frankfurt_am_Main: 'Francfort-sur-le-Main',
    stuttgart: 'Stuttgart',
    düsseldorf: 'Düsseldorf',
    leipzig: 'Leipzig',
    dortmund: 'Dortmund',
    essen: 'Essen',
    bremen: 'Brême',
    hanover: 'Hanovre',
    nuremberg: 'Nuremberg',
    duisburg: 'Duisbourg',
    bochum: 'Bochum',
    wuppertal: 'Wuppertal',
    mannheim: 'Mannheim',
    aachen: 'Aix-la-Chapelle',
    mainz: 'Mayence',
    saarbrücken: 'Sarrebruck',
    leverkusen: 'Leverkusen',
    heidelberg: 'Heidelberg',
    
    accra: 'Accra',
    kumasi: 'Kumasi',
    sekondi_Takoradi: 'Sekondi-Takoradi',
    tamale: 'Tamale',
    ashaiman: 'Ashaiman',
    sunyani: 'Sunyani',
    cape_Coast: 'Cape Coast',
    obuasi: 'Obuasi',
    teshie: 'Teshie',
    tema: 'Tema',
    
    athens: 'Athènes',
    thessaloniki: 'Thessalonique',
    patras: 'Patras',
    
    nuuk: 'Nuuk',
    sisimiut: 'Sisimiut',
    ilulissat: 'Ilulissat',
    
    st_George: "Saint-Georges",
    grenville: 'Grenville',
    gouyave: 'Gouyave',
    
    les_Abymes: 'Les Abymes',
    baie_Mahault: 'Baie-Mahault',
    le_Gosier: 'Le Gosier',
    
    dededo_Village: 'Dededo Village',
    yigo_Village: 'Yigo Village',
    tamuning_Tumon_Harmon_Village: 'Tamuning-Tumon-Harmon Village',
    
    guatemala_City: 'Guatemala City',
    villa_Nueva: 'Villa Nueva',
    mixco: 'Mixco',
    
    st_Peter_Port: 'Saint Peter Port',
    st_Sampson: 'Saint Sampson',
    sark: 'Sark',
    
    conakry  : 'Conakry',
    camayenne: 'Camayenne',
    nzérékoré: 'Nzérékoré',
    kankan: 'Kankan',
    manéah: 'Manéah',
    dubr: 'Dubréka',
    kindia: 'Kindia',
    siguiri: 'Siguiri',
    kissidougou: 'Kissidougou',
    kamsar: 'Kamsar',
    labé: 'Labé',
    gueckedou: 'Guéckédou',
    
    bissau: 'Bissau',
    gabú: 'Gabú',
    bafatá: 'Bafatá',
    
    georgetown: 'Georgetown',
    linden: 'Linden',
    new_Amsterdam: 'New Amsterdam',
    
    port_au_Prince: 'Port-au-Prince',
    delmas_73: 'Delmas 73',
    port_de_Paix: 'Port-de-Paix',
    
    tegucigalpa: 'Tegucigalpa',
    san_Pedro_Sula: 'San Pedro Sula',
    la_Ceiba: 'La Ceiba',
    
    hong_Kong: 'Hong Kong',
    victoria: 'Victoria',
    tuen_Mun: 'Tuen Mun',
    
    budapest: 'Budapest',
    debrecen: 'Debrecen',
    szeged: 'Szeged',
    
    reykjavik: 'Reykjavik',
    kópavogur: 'Kópavogur',
    hafnarfjörður: 'Hafnarfjörður',
    
    mumbai: 'Bombay',
    delhi: 'Delhi',
    bangalore: 'Bangalore',
    
    jakarta: 'Jakarta',
    surabaya: 'Surabaya',
    bekasi: 'Bekasi',
    
    tehran: 'Téhéran',
    mashhad: 'Mashhad',
    isfahan: 'Ispahan',
    
    baghdad: 'Bagdad',
    mosul: 'Mossoul',
    basra: 'Bassorah',
    
    dublin: 'Dublin',
    belfast: 'Belfast',
    cork: 'Cork',
    
    douglas: 'Douglas',
    ramsey: 'Ramsey',
    peel: 'Peel',
    
    rome: 'Rome',
    milan: 'Milan',
    naples: 'Naples',
    turin: 'Turin',
    palermo: 'Palerme',
    genoa: 'Gênes',
    bologna: 'Bologne',
    florence: 'Florence',
    bari: 'Bari',
    catania: 'Catane',
    verona: 'Vérone',
    venice: 'Venise',
    
    kingston: 'Kingston',
    spanish_Town: 'Spanish Town',
    portmore: 'Portmore',
    
    tokyo: 'Tokyo',
    yokohama: 'Yokohama',
    osaka: 'Osaka',
    
    amman: 'Amman',
    zarqa: 'Zarqa',
    irbid: 'Irbid',
    
    almaty: 'Almaty',
    astana: 'Astana',
    shymkent: 'Shymkent',
    
    baragoi: 'Baragoi',
    bondo: 'Bondo',
    bungoma: 'Bungoma',
    busia: 'Busia',
    butere: 'Butere',
    dadaab: 'Dadaab',
    diani_Beach: 'Diani Beach',
    eldoret: 'Eldoret',
    emali: 'Emali',
    embu: 'Embu',
    garissa: 'Garissa',
    gede: 'Gede',
    gem: 'Gem',
    hola: 'Hola',
    homa_Bay: 'Homa Bay',
    isiolo: 'Isiolo',
    kitui: 'Kitui',
    kibwezi: 'Kibwezi',
    kajiado: 'Kajiado',
    kakamega: 'Kakamega',
    kakuma: 'Kakuma',
    kapenguria: 'Kapenguria',
    kericho: 'Kericho',
    keroka: 'Keroka',
    kiambu: 'Kiambu',
    kilifi: 'Kilifi',
    kisii: 'Kisii',
    kisumu: 'Kisumu',
    kitale: 'Kitale',
    lamu: 'Lamu',
    langata: 'Langata',
    litein: 'Litein',
    lodwar: 'Lodwar',
    lokichoggio: 'Lokichoggio',
    londiani: 'Londiani',
    loyangalani: 'Loyangalani',
    machakos: 'Machakos',
    makindu: 'Makindu',
    malindi: 'Malindi',
    mandera: 'Mandera',
    maralal: 'Maralal',
    marsabit: 'Marsabit',
    meru: 'Meru',
    mombasa: 'Mombasa',
    moyale: 'Moyale',
    mtwapa: 'Mtwapa',
    mumias: 'Mumias',
    muranga: 'Muranga',
    mutomo: 'Mutomo',
    nairobi: 'Nairobi',
    naivasha: 'Naivasha',
    nakuru: 'Nakuru',
    namanga: 'Namanga',
    nanyuki: 'Nanyuki',
    naro_Moru: 'Naro Moru',
    narok: 'Narok',
    nyahururu: 'Nyahururu',
    nyeri: 'Nyeri',
    ruiru: 'Ruiru',
    siaya: 'Siaya',
    shimoni: 'Shimoni',
    takaungu: 'Takaungu',
    thika: 'Thika',
    ugunja: 'Ugunja',
    vihiga: 'Vihiga',
    voi: 'Voi',
    wajir: 'Wajir',
    watamu: 'Watamu',
    webuye: 'Webuye',
    wote: 'Wote',
    wundanyi: 'Wundanyi',
    
    tarawa: 'Tarawa',
    betio_Village: 'Betio Village',
    bikenibeu_Village: 'Bikenibeu Village',
    
    pyongyang: 'Pyongyang',
    nampo: 'Nampo',
    hamhung: 'Hamhung',
    
    seoul: 'Séoul',
    busan: 'Busan',
    incheon: 'Incheon',
    
    al_Ahmadi: 'Al Ahmadi',
    hawalli: 'Hawalli',
    sabah_as_Salim: 'Sabah as Salim',

    bishkek: 'Bichkek',
    osh: 'Och',
    jalal_Abad: 'Jalal-Abad',

    vientiane: 'Vientiane',
    savannakhet: 'Savannakhet',
    pakse: 'Pakse',

    riga: 'Riga',
    daugavpils: 'Daugavpils',
    liepaja: 'Liepāja',

    beirut: 'Beyrouth',
    tripoli: 'Tripoli',
    sidon: 'Sidon',

    maseru: 'Maseru',
    teyateyaneng: 'Teyateyaneng',
    mafeteng: 'Mafeteng',

    monrovia: 'Monrovia',
    gbarnga: 'Gbarnga',
    buchanan: 'Buchanan',

    tripoli: 'Tripoli',
    benghazi: 'Benghazi',
    misrata: 'Misrata',

    schaan: 'Schaan',
    vaduz: 'Vaduz',
    triesen: 'Triesen',

    vilnius: 'Vilnius',
    kaunas: 'Kaunas',
    klaipeda: 'Klaipėda',

    luxembourg: 'Luxembourg',
    esch_sur_Alzette: 'Esch-sur-Alzette',
    dudelange: 'Dudelange',

    macao: 'Macao',
    taipa: 'Taipa',
    luhuan: 'Luhuan',

    antananarivo: 'Antananarivo',
    toamasina: 'Toamasina',
    antsirabe: 'Antsirabe',

    chitipa: 'Chitipa',
    chilumba: 'Chilumba',
    ekwendeni: 'Ekwendeni',
    karonga: 'Karonga',
    likoma: 'Likoma',
    livingstonia: 'Livingstonia',
    mzimba: 'Mzimba',
    mzuzu: 'Mzuzu',
    nkhata_Bay: 'Nkhata Bay',
    rumphi: 'Rumphi',
    chintheche: 'Chintheche',
    embangweni: 'Embangweni',
    aaron: 'Aaron',
    chipoka: 'Chipoka',
    dedza: 'Dedza',
    dowa: 'Dowa',
    kasungu: 'Kasungu',
    lilongwe: 'Lilongwe',
    mchinji: 'Mchinji',
    mponela: 'Mponela',
    mua: 'Mua',
    namitete: 'Namitete',
    madisi: 'Madisi',
    nkhota_Kota: 'Nkhota Kota',
    nkhoma: 'Nkhoma',
    ntcheu: 'Ntcheu',
    ntchisi: 'Ntchisi',
    salima: 'Salima',
    balaka: 'Balaka',
    bangula: 'Bangula',
    blantyre: 'Blantyre',
    chikwawa: 'Chikwawa',
    chiradzulu: 'Chiradzulu',
    chiwembe: 'Chiwembe',
    domasi: 'Domasi',
    limbe: 'Limbe',
    liwonde: 'Liwonde',
    luchenza: 'Luchenza',
    machinga: 'Machinga',
    malosa: 'Malosa',
    mangochi: 'Mangochi',
    marka: 'Marka',
    monkey_Bay: 'Monkey Bay',
    mulanje: 'Mulanje',
    njata: 'Njata',
    nkaya: 'Nkaya',
    nsanje: 'Nsanje',
    phalombe: 'Phalombe',
    thyolo: 'Thyolo',
    zalewa: 'Zalewa',
    zomba: 'Zomba',

    klang_Valley: 'Klang Valley',
    george_Town: 'George Town',
    johor_Bahru: 'Johor Bahru',

    male: 'Malé',
    fuvahmulah: 'Fuvahmulah',
    hithadhoo: 'Hithadhoo',

    bamako: 'Bamako',
    sikasso: 'Sikasso',
    kalabancoro: 'Kalabancoro',
    koutiala: 'Koutiala',
    ségou: 'Ségou',
    kayes: 'Kayes',
    mopti: 'Mopti',
    gao: 'Gao',
    kati: 'Kati',
    san: 'San',
    bougouni: 'Bougouni',
    tombouctou: 'Tombouctou',

    birgu: 'Birgu',
    bormla: 'Bormla',
    mdina: 'Mdina',

    majuro: 'Majuro',
    ebaye: 'Ebaye',
    arno: 'Arno',

    fort_de_France: 'Fort-de-France',
    le_Lamentin: 'Le Lamentin',
    le_Robert: 'Le Robert',

    nouakchott: 'Nouakchott',
    nouadhibou: 'Nouadhibou',
    kiffa: 'Kiffa',

    port_Louis: 'Port-Louis',
    vacoas: 'Vacoas',
    beau_Bassin_Rose_Hill: 'Beau Bassin-Rose Hill',

    mamoudzou: 'Mamoudzou',
    koungou: 'Koungou',
    dzaoudzi: 'Dzaoudzi',

    mexico_City: 'Mexico City',
    tijuana: 'Tijuana',
    ecatepec: 'Ecatepec',

    monaco: 'Monaco',
    monte_Carlo: 'Monte-Carlo',
    la_Condamine: 'La Condamine',

    ulaanbaatar: 'Oulan-Bator',
    erdenet: 'Erdenet',
    darkhan: 'Darkhan',

    podgorica: 'Podgorica',
    nikšić: 'Nikšić',
    pljevlja: 'Pljevlja',

    brades: 'Brades',
    cork_Hill: 'Cork Hill',
    salem: 'Salem',

    casablanca: 'Casablanca',
    fez: 'Fès',
    tangier: 'Tanger',
    marrakesh: 'Marrakech',
    salé: 'Salé',
    meknes: 'Meknès',
    rabat: 'Rabat',
    oujda: 'Oujda',
    kenitra: 'Kénitra',
    agadir: 'Agadir',
    tetouan: 'Tétouan',
    temara: 'Témara',
    safi: 'Safi',
    mohammedia: 'Mohammédia',
    khouribga: 'Khouribga',
    el_Jadida: 'El Jadida',
    beni_Mellal: 'Beni Mellal',
    aït_Melloul: 'Aït Melloul',
    nador: 'Nador',
    dar_Bouazza: 'Dar Bouazza',
    taza: 'Taza',
    settat: 'Settat',
    berrechid: 'Berrechid',
    khemisset: 'Khémisset',
    inezgane: 'Inezgane',
    ksar_El_Kebir: 'Ksar El Kebir',
    larache: 'Larache',
    guelmim: 'Guelmim',
    khenifra: 'Khénifra',
    berkane: 'Berkane',
    taourirt: 'Taourirt',
    bouskoura: 'Bouskoura',
    fquih_Ben_Salah: 'Fquih Ben Salah',
    dcheira_El_Jihadia: 'Dcheira El Jihadia',
    oued_Zem: 'Oued Zem',
    el_Kelaa_Des_Sraghna: 'El Kelaa des Sraghna',
    sidi_Slimane: 'Sidi Slimane',
    errachidia: 'Errachidia',
    guercif: 'Guercif',
    oulad_Teima: 'Oulad Teima',
    ben_Guerir: 'Ben Guerir',
    tifelt: 'Tifelt',
    lqliaa: 'Lqliaa',
    taroudant: 'Taroudant',
    sefrou: 'Sefrou',
    essaouira: 'Essaouira',
    fnideq: 'Fnideq',
    sidi_Kacem: 'Sidi Kacem',
    tiznit: 'Tiznit',
    tan_Tan: 'Tan-Tan',
    ouarzazate: 'Ouarzazate',
    souk_El_Arbaa: 'Souk El Arbaa',
    youssoufia: 'Youssoufia',
    lahraouyine: 'Lahraouyine',
    martil: 'Martil',
    ain_Harrouda: 'Aïn Harrouda',
    suq_as_Sabt_Awlad_an_Nama: 'Suq as-Sabt Awlad an-Nama',
    skhirat: 'Skhirat',
    ouazzane: 'Ouazzane',
    benslimane: 'Benslimane',
    al_Hoceima: 'Al Hoceïma',
    beni_Ansar: 'Beni Ansar',
    m_diq: 'M\'diq',
    sidi_Bennour: 'Sidi Bennour',
    midelt: 'Midelt',
    azrou: 'Azrou',
    drargua: 'Drargua',

    angoche: 'Angoche',
    beira: 'Beira',
    catandica: 'Catandica',
    chibuto: 'Chibuto',
    chicualacuala: 'Chicualacuala',
    chimoio: 'Chimoio',
    chinde: 'Chinde',
    chokwe: 'Chokwé',
    cuamba: 'Cuamba',
    dondo: 'Dondo',
    gurùé: 'Gurùé',
    inhambane: 'Inhambane',
    lichinga: 'Lichinga',
    manica: 'Manica',
    maputo: 'Maputo',
    marracuene: 'Marracuene',
    matola: 'Matola',
    maxixe: 'Maxixe',
    moatize: 'Moatize',
    moçambique: 'Moçambique',
    mocímboa_da_Praia: 'Mocímboa da Praia',
    mocuba: 'Mocuba',
    montepuez: 'Montepuez',
    mueda: 'Mueda',
    naamcha: 'Naamcha',
    nacala: 'Nacala',
    nampula: 'Nampula',
    palma: 'Palma',
    pemba: 'Pemba',
    ponta_d_Ouro: 'Ponta d\'Ouro',
    quelimane: 'Quelimane',
    tete: 'Tete',
    vilankulo: 'Vilankulo',
    xai_Xai: 'Xai-Xai',
    zavala: 'Zavala',

    yangon: 'Rangoun',
    mandalay: 'Mandalay',
    naypyidaw: 'Naypyidaw',

    windhoek: 'Windhoek',
    walvisbaai: 'Walvisbaai',
    rundu: 'Rundu',
    oshakati: 'Oshakati',
    swakopmund: 'Swakopmund',
    rehoboth: 'Rehoboth',
    katima_Mulilo: 'Katima Mulilo',
    grootfontein: 'Grootfontein',
    ondangwa: 'Ondangwa',
    okahandja: 'Okahandja',
    opuwo: 'Opuwo',
    otjiwarongo: 'Otjiwarongo',
    tsumeb: 'Tsumeb',
    gobabis: 'Gobabis',
    keetmanshoop: 'Keetmanshoop',
    lüderitz: 'Lüderitz',
    mariental: 'Mariental',
    oshikango: 'Oshikango',
    omatjete: 'Omatjete',
    ongwediva: 'Ongwediva',
    outjo: 'Outjo',
    otjimbingwe: 'Otjimbingwe',
    okakarara: 'Okakarara',
    khorixas: 'Khorixas',
    omaruru: 'Omaruru',
    kamanjab: 'Kamanjab',
    maltahöhe: 'Maltahöhe',
    eenhana: 'Eenhana',
    otavi: 'Otavi',
    arandis: 'Arandis',
    karibib: 'Karibib',
    aroab: 'Aroab',
    berseba: 'Berseba',
    kalkfeld: 'Kalkfeld',
    kangongo: 'Kangongo',
    hentiesbaai: 'Hentiesbaai',
    outapi: 'Outapi',
    okatana: 'Okatana',
    karasburg: 'Karasburg',
    tsintsabis: 'Tsintsabis',
    oranjemund: 'Oranjemund',
    bethanie: 'Bethanie',
    omuthiya: 'Omuthiya',
    aranos: 'Aranos',
    uis: 'Uis',
    usakos: 'Usakos',
    hoachanas: 'Hoachanas',
    nkurenkuru: 'Nkurenkuru',

    arijejen: 'Arijejen',
    denigomodu: 'Denigomodu',
    menen: 'Menen',

    kathmandu: 'Katmandou',
    bharatpur: 'Bharatpur',
    patan: 'Patan',

    tilburg: 'Tilburg',
    amsterdam: 'Amsterdam',
    rotterdam: 'Rotterdam',
    the_Hague: 'La Haye',
    utrecht: 'Utrecht',
    maastricht: 'Maastricht',
    eindhoven: 'Eindhoven',
    groningen: 'Groningue',
    almere: 'Almere',
    breda: 'Bréda',
    nijmegen: 'Nimègue',
    arnhem: 'Arnhem',
    haarlem: 'Haarlem',
    enschede: 'Enschede',
    s_Hertogenbosch: 'Bois-le-Duc',
    amersfoort: 'Amersfoort',
    zaanstad: 'Zaanstad',
    apeldoorn: 'Apeldoorn',
    zwolle: 'Zwolle',
    zoetermeer: 'Zoetermeer',
    leeuwarden: 'Leeuwarden',
    leiden: 'Leyde',
    dordrecht: 'Dordrecht',
    alphen_aan_den_Rijn: 'Alphen aan den Rijn',
    alkmaar: 'Alkmaar',
    delft: 'Delft',
    emmen: 'Emmen',
    deventer: 'Deventer',
    helmond: 'Helmond',
    hilversum: 'Hilversum',
    heerlen: 'Heerlen',
    lelystad: 'Lelystad',
    purmerend: 'Purmerend',
    hengelo: 'Hengelo',
    schiedam: 'Schiedam',
    zaandam: 'Zaandam',
    hoofddorp: 'Hoofddorp',
    vlaardingen: 'Vlaardingen',
    gouda: 'Gouda',
    hoorn: 'Hoorn',
    almelo: 'Almelo',
    spijkenisse: 'Spijkenisse',
    amstelveen: 'Amstelveen',
    assen: 'Assen',
    velsen_Zuid: 'Velsen-Zuid',
    capelle_aan_den_IJssel: 'Capelle aan den IJssel',
    veenendaal: 'Veenendaal',
    katwijk: 'Katwijk',
    zeist: 'Zeist',
    nieuwegein: 'Nieuwegein',
    scheveningen: 'Scheveningen',
    heerhugowaard: 'Heerhugowaard',
    roermond: 'Roermond',
    oosterhout: 'Oosterhout',
    rijswijk: 'Rijswijk',
    houten: 'Houten',
    middelburg: 'Middelburg',
    harderwijk: 'Harderwijk',
    barendrecht: 'Barendrecht',
    ijmuiden: 'IJmuiden',
    zutphen: 'Zutphen',
    soest: 'Soest',
    ridderkerk: 'Ridderkerk',
    schagen: 'Schagen',
    veldhoven: 'Veldhoven',
    kerkrade: 'Kerkrade',
    zwijndrecht: 'Zwijndrecht',
    zevenaar: 'Zevenaar',
    noordwijk: 'Noordwijk',

    nouméa: 'Nouméa',
    le_Mont_Dore: 'Le Mont-Dore',
    dumbéa: 'Dumbéa',

    auckland: 'Auckland',
    christchurch: 'Christchurch',
    wellington: 'Wellington',

    managua: 'Managua',
    león: 'León',
    tipitapa: 'Tipitapa',

    niamey: 'Niamey',
    maradi: 'Maradi',
    zinder: 'Zinder',

    lagos: 'Lagos',
    onitsha: 'Onitsha',
    kano: 'Kano',
    ibadan: 'Ibadan',
    port_Harcourt: 'Port Harcourt',
    abuja: 'Abuja',
    benin_City: 'Benin City',
    aba: 'Aba',
    kaduna: 'Kaduna',
    ilorin: 'Ilorin',
    enugu: 'Enugu',
    jos: 'Jos',

    oslo: 'Oslo',
    bergen: 'Bergen',
    stavanger: 'Stavanger',

    muscat: 'Mascate',
    seeb: 'Seeb',
    bawshar: 'Bawshar',

    karachi: 'Karachi',
    lahore: 'Lahore',
    faisalabad: 'Faisalabad',

    gaza: 'Gaza',
    khan_Yunis: 'Khan Younis',
    jabalia: 'Jabalia',

    panama_City: 'Panama',
    san_Miguelito: 'San Miguelito',
    juan_Díaz: 'Juan Díaz',

    asunción: 'Asunción',
    ciudad_del_Este: 'Ciudad del Este',
    san_Lorenzo: 'San Lorenzo',

    lima: 'Lima',
    arequipa: 'Arequipa',
    trujillo: 'Trujillo',

    quezon: 'Quezon',
    manila: 'Manille',
    caloocan: 'Caloocan',

    warsaw: 'Varsovie',
    kraków: 'Cracovie',
    łódź: 'Łódź',

    lisbon: 'Lisbonne',
    porto: 'Porto',
    amadora: 'Amadora',

    san_Juan: 'San Juan',
    bayamón: 'Bayamón',
    carolina: 'Carolina',

    doha: 'Doha',
    al_Rayyan: 'Al Rayyan',
    umm_Ghuwaylīnah: 'Umm Ghuwaylīnah',

    bucharest: 'Bucarest',
    iași: 'Iași',
    cluj_Napoca: 'Cluj-Napoca',

    moscow: 'Moscou',
    saint_Petersburg: 'Saint-Pétersbourg',
    novosibirsk: 'Novossibirsk',

    kigali: 'Kigali',
    gisenyi: 'Gisenyi',
    butare: 'Butare',

    saint_Denis: 'Saint-Denis',
    saint_Paul: 'Saint-Paul',
    saint_Pierre: 'Saint-Pierre',

    sao_Tome: 'São Tomé',
    santo_Amaro: 'Santo Amaro',
    neves: 'Neves',

    riyadh: 'Riyad',
    jeddah: 'Djeddah',
    mecca: 'La Mecque',

    pikine: 'Pikine',
    dakar: 'Dakar',
    touba: 'Touba',

    belgrade: 'Belgrade',
    novi_Sad: 'Novi Sad',
    niš: 'Niš',

    victoria: 'Victoria',
    perseverance: 'Persévérance',
    anse_Boileau: 'Anse Boileau',

    freetown: 'Freetown',
    kenema: 'Kenema',
    bo: 'Bo',

    singapore: 'Singapour',
    bedok_New_Town: 'Bedok New Town',
    ulu_Bedok: 'Ulu Bedok',

    bratislava: 'Bratislava',
    košice: 'Košice',
    žilina: 'Žilina',

    ljubljana: 'Ljubljana',
    maribor: 'Maribor',
    kranj: 'Kranj',

    mogadishu: 'Mogadiscio',
    hargeysa: 'Hargeisa',
    berbera: 'Berbera',

    mount_Ayliff: 'Mount Ayliff',
    newcastle: 'Newcastle',
    east_London: 'East London',
    rustenburg: 'Rustenburg',
    worcester: 'Worcester',
    polokwane: 'Polokwane',
    beaufort_West: 'Beaufort West',
    queenstown: 'Queenstown',
    cape_Town: 'Le Cap',
    germiston: 'Germiston',
    johannesburg: 'Johannesburg',
    pretoria: 'Pretoria',
    klerksdorp: 'Klerksdorp',
    vryburg: 'Vryburg',
    nelspruit: 'Nelspruit',
    durban: 'Durban',
    sasolburg: 'Sasolburg',
    kimberley: 'Kimberley',
    george: 'George',
    ermelo: 'Ermelo',
    ixopo: 'Ixopo',
    kwaDukuza: 'KwaDukuza',
    barkly_East: 'Barkly East',
    kuruman: 'Kuruman',
    richards_Bay: 'Richards Bay',
    welkom: 'Welkom',
    bloemfontein: 'Bloemfontein',
    giyani: 'Giyani',
    springbok: 'Springbok',
    port_Elizabeth: 'Port Elizabeth',
    mahikeng: 'Mahikeng',
    middelburg: 'Middelburg',
    mthatha: 'Mthatha',
    bredasdorp: 'Bredasdorp',
    de_Aar: 'De Aar',
    vereeniging: 'Vereeniging',
    groblersdal: 'Groblersdal',
    phuthaditjhaba: 'Phuthaditjhaba',
    port_Shepstone: 'Port Shepstone',
    pietermaritzburg: 'Pietermaritzburg',
    mkuze: 'Mkuze',
    dundee: 'Dundee',
    ladysmith: 'Ladysmith',
    thohoyandou: 'Thohoyandou',
    modimolle: 'Modimolle',
    moorreesburg: 'Moorreesburg',
    randfontein: 'Randfontein',
    trompsburg: 'Trompsburg',
    upington: 'Upington',
    ulundi: 'Ulundi',

    juba: 'Djouba',
    winejok: 'Winejok',
    yei: 'Yei',

    madrid: 'Madrid',
    barcelona: 'Barcelone',
    valencia: 'Valence',

    colombo: 'Colombo',
    dehiwala_Mount_Lavinia: 'Dehiwala-Mount Lavinia',
    maharagama: 'Maharagama',

    khartoum: 'Khartoum',
    omdurman: 'Omdourman',
    nyala: 'Nyala',

    paramaribo: 'Paramaribo',
    lelydorp: 'Lelydorp',
    brokopondo: 'Brokopondo',

    stockholm: 'Stockholm',
    gothenburg: 'Göteborg',
    malmö: 'Malmö',

    zurich: 'Zurich',
    geneva: 'Genève',
    basel: 'Bâle',

    aleppo: 'Alep',
    damascus: 'Damas',
    hims: 'Homs',

    new_Taipei: 'New Taipei',
    taichung: 'Taichung',
    kaohsiung: 'Kaohsiung',

    dushanbe: 'Douchanbé',
    khujand: 'Khodjent',
    bokhtar: 'Bokhtar',

    dar_es_Salaam: 'Dar es Salam',
    mwanza: 'Mwanza',
    arusha: 'Arusha',

    bangkok: 'Bangkok',
    nonthaburi: 'Nonthaburi',
    nakhon_Ratchasima: 'Nakhon Ratchasima',

    lomé: 'Lomé',
    sokodé: 'Sokodé',
    kara: 'Kara',

    chaguanas: 'Chaguanas',
    mon_Repos: 'Mon Repos',
    san_Fernando: 'San Fernando',

    tunis: 'Tunis',
    ṣafāqis: 'Ṣafāqis',
    sūsah: 'Sousse',
    sidi_Hassine: 'Sidi Hassine',
    ariana: 'Ariana',
    la_Soukra: 'La Soukra',
    ettadhamen_Mnihla: 'Ettadhamen-Mnihla',
    el_Mourouj: 'El Mourouj',
    mohamedia_Fouchana: 'Mohamedia-Fouchana',
    bizerte: 'Bizerte',
    sousse: 'Sousse',
    sfax: 'Sfax',
    kairouan: 'Kairouan',
    gabès: 'Gabès',

    istanbul: 'Istanbul',
    ankara: 'Ankara',
    izmir: 'Izmir',

    ashgabat: 'Achgabat',
    türkmenabat: 'Türkmenabat',
    daşoguz: 'Daşoguz',

    funafuti: 'Funafuti',
    savave_Village: 'Savave Village',
    motufoua_School: 'Motufoua School',

    kampala: 'Kampala',
    nansana: 'Nansana',
    kira: 'Kira',
    ssabagabo: 'Ssabagabo',
    mbarara: 'Mbarara',
    mukono: 'Mukono',
    njeru: 'Njeru',
    gulu: 'Gulu',
    lugazi: 'Lugazi',
    masaka: 'Masaka',
    kasese: 'Kasese',
    hoima: 'Hoima',
    lira: 'Lira',
    mityana: 'Mityana',
    mubende: 'Mubende',
    masindi: 'Masindi',
    mbale: 'Mbale',
    jinja: 'Jinja',
    entebbe: 'Entebbe',
    kitgum: 'Kitgum',

    kyiv: 'Kyiv',
    kharkiv: 'Kharkiv',
    odesa: 'Odessa',

    dubai: 'Dubaï',
    abu_Dhabi: 'Abou Dabi',
    sharjah: 'Charjah',

    bath: 'Bath',
    birmingham: 'Birmingham',
    bradford: 'Bradford',
    brighton_and_Hove: 'Brighton & Hove',
    bristol: 'Bristol',
    cambridge: 'Cambridge',
    canterbury: 'Canterbury',
    carlisle: 'Carlisle',
    chelmsford: 'Chelmsford',
    chester: 'Chester',
    chichester: 'Chichester',
    colchester: 'Colchester',
    coventry: 'Coventry',
    derby: 'Derby',
    doncaster: 'Doncaster',
    durham: 'Durham',
    ely: 'Ely',
    exeter: 'Exeter',
    gloucester: 'Gloucester',
    hereford: 'Hereford',
    kingston_upon_Hull: 'Kingston-upon-Hull',
    lancaster: 'Lancaster',
    leeds: 'Leeds',
    leicester: 'Leicester',
    lichfield: 'Lichfield',
    lincoln: 'Lincoln',
    liverpool: 'Liverpool',
    london: 'Londres',
    manchester: 'Manchester',
    milton_Keynes: 'Milton Keynes',
    newcastle_upon_Tyne: 'Newcastle-upon-Tyne',
    norwich: 'Norwich',
    nottingham: 'Nottingham',
    oxford: 'Oxford',
    peterborough: 'Peterborough',
    plymouth: 'Plymouth',
    portsmouth: 'Portsmouth',
    preston: 'Preston',
    ripon: 'Ripon',
    salford: 'Salford',
    salisbury: 'Salisbury',
    sheffield: 'Sheffield',
    southampton: 'Southampton',
    southend_on_Sea: 'Southend-on-Sea',
    st_Albans: 'St Albans',
    stoke_on_Trent: 'Stoke-on-Trent',
    sunderland: 'Sunderland',
    truro: 'Truro',
    wakefield: 'Wakefield',
    wells: 'Wells',
    westminster: 'Westminster',
    winchester: 'Winchester',
    wolverhampton: 'Wolverhampton',
    worcester: 'Worcester',
    york: 'York',

    new_York: 'New York',
    los_Angeles: 'Los Angeles',
    chicago: 'Chicago',
    houston: 'Houston',
    phoenix: 'Phoenix',
    philadelphia: 'Philadelphie',
    san_Antonio: 'San Antonio',
    san_Diego: 'San Diego',
    dallas: 'Dallas',
    austin: 'Austin',
    jacksonville: 'Jacksonville',
    san_Jose: 'San José',
    fort_Worth: 'Fort Worth',
    columbus: 'Columbus',
    charlotte: 'Charlotte',
    indianapolis: 'Indianapolis',
    san_Francisco: 'San Francisco',
    seattle: 'Seattle',
    denver: 'Denver',
    oklahoma_City: 'Oklahoma City',
    nashville: 'Nashville',
    el_Paso: 'El Paso',
    washington: 'Washington',
    las_Vegas: 'Las Vegas',
    boston: 'Boston',
    portland: 'Portland',
    louisville: 'Louisville',
    memphis: 'Memphis',
    detroit: 'Détroit',
    baltimore: 'Baltimore',
    milwaukee: 'Milwaukee',
    albuquerque: 'Albuquerque',
    tucson: 'Tucson',
    fresno: 'Fresno',
    sacramento: 'Sacramento',
    mesa: 'Mesa',
    kansas_City: 'Kansas City',
    atlanta: 'Atlanta',
    colorado_Springs: 'Colorado Springs',
    omaha: 'Omaha',
    raleigh: 'Raleigh',
    virginia_Beach: 'Virginia Beach',
    long_Beach: 'Long Beach',
    miami: 'Miami',
    oakland: 'Oakland',
    minneapolis: 'Minneapolis',
    tulsa: 'Tulsa',
    bakersfield: 'Bakersfield',
    tampa: 'Tampa',
    wichita: 'Wichita',
    arlington: 'Arlington',
    new_Orleans: 'La Nouvelle-Orléans',
    cleveland: 'Cleveland',
    anaheim: 'Anaheim',
    honolulu: 'Honolulu',
    henderson: 'Henderson',
    stockton: 'Stockton',
    riverside: 'Riverside',
    lexington: 'Lexington',
    corpus_Christi: 'Corpus Christi',
    orlando: 'Orlando',
    irvine: 'Irvine',
    cincinnati: 'Cincinnati',
    santa_Ana: 'Santa Ana',
    newark: 'Newark',
    saint_Paul: 'Saint Paul',
    pittsburgh: 'Pittsburgh',
    greensboro: 'Greensboro',
    lincoln: 'Lincoln',
    durham: 'Durham',
    plano: 'Plano',
    anchorage: 'Anchorage',
    jersey_City: 'Jersey City',
    st_Louis: 'Saint-Louis',
    chandler: 'Chandler',
    north_Las_Vegas: 'North Las Vegas',
    chula_Vista: 'Chula Vista',
    buffalo: 'Buffalo',
    gilbert: 'Gilbert',
    reno: 'Reno',
    madison: 'Madison',
    fort_Wayne: 'Fort Wayne',
    toledo: 'Toledo',
    lubbock: 'Lubbock',
    st_Petersburg: 'Saint-Pétersbourg',
    laredo: 'Laredo',
    irving: 'Irving',
    chesapeake: 'Chesapeake',
    glendale: 'Glendale',
    winston_Salem: 'Winston-Salem',
    scottsdale: 'Scottsdale',
    garland: 'Garland',
    boise: 'Boise',
    norfolk: 'Norfolk',
    port_St_Lucie: 'Port St. Lucie',
    spokane: 'Spokane',
    richmond: 'Richmond',
    fremont: 'Fremont',
    huntsville: 'Huntsville',
    tacoma: 'Tacoma',
    baton_Rouge: 'Baton Rouge',
    santa_Clarita: 'Santa Clarita',
    san_Bernardino: 'San Bernardino',
    hialeah: 'Hialeah',
    frisco: 'Frisco',
    modesto: 'Modesto',
    cape_Coral: 'Cape Coral',
    fontana: 'Fontana',
    moreno_Valley: 'Moreno Valley',
    des_Moines: 'Des Moines',
    rochester: 'Rochester',
    fayetteville: 'Fayetteville',
    yonkers: 'Yonkers',
    mckinney: 'McKinney',
    worcester: 'Worcester',
    salt_Lake_City: 'Salt Lake City',
    little_Rock: 'Little Rock',
    columbus: 'Columbus',
    augusta: 'Augusta',
    sioux_Falls: 'Sioux Falls',
    grand_Prairie: 'Grand Prairie',
    tallahassee: 'Tallahassee',
    amarillo: 'Amarillo',
    oxnard: 'Oxnard',
    peoria: 'Peoria',
    overland_Park: 'Overland Park',
    montgomery: 'Montgomery',
    birmingham: 'Birmingham',
    grand_Rapids: 'Grand Rapids',
    knoxville: 'Knoxville',
    vancouver: 'Vancouver',
    huntington_Beach: 'Huntington Beach',
    providence: 'Providence',
    brownsville: 'Brownsville',
    glendale: 'Glendale',
    akron: 'Akron',
    tempe: 'Tempe',
    newport_News: 'Newport News',
    chattanooga: 'Chattanooga',
    mobile: 'Mobile',
    fort_Lauderdale: 'Fort Lauderdale',
    cary: 'Cary',
    shreveport: 'Shreveport',
    ontario: 'Ontario',
    eugene: 'Eugene',
    aurora: 'Aurora',
    elk_Grove: 'Elk Grove',
    salem: 'Salem',
    santa_Rosa: 'Santa Rosa',
    clarksville: 'Clarksville',
    rancho_Cucamonga: 'Rancho Cucamonga',
    oceanside: 'Oceanside',
    springfield: 'Springfield',
    pembroke_Pines: 'Pembroke Pines',
    garden_Grove: 'Garden Grove',
    fort_Collins: 'Fort Collins',
    lancaster: 'Lancaster',
    palmdale: 'Palmdale',
    murfreesboro: 'Murfreesboro',
    salinas: 'Salinas',
    corona: 'Corona',
    killeen: 'Killeen',
    hayward: 'Hayward',
    paterson: 'Paterson',
    macon: 'Macon',
    lakewood: 'Lakewood',
    alexandria: 'Alexandria',
    roseville: 'Roseville',
    surprise: 'Surprise',
    springfield: 'Springfield',
    charleston: 'Charleston',
    kansas_City: 'Kansas City',
    sunnyvale: 'Sunnyvale',
    bellevue: 'Bellevue',
    hollywood: 'Hollywood',
    denton: 'Denton',
    escondido: 'Escondido',
    joliet: 'Joliet',
    naperville: 'Naperville',
    bridgeport: 'Bridgeport',
    savannah: 'Savannah',
    mesquite: 'Mesquite',
    pasadena: 'Pasadena',
    rockford: 'Rockford',
    pomona: 'Pomona',
    jackson: 'Jackson',
    olathe: 'Olathe',
    gainesville: 'Gainesville',
    mcallen: 'McAllen',
    syracuse: 'Syracuse',
    waco: 'Waco',
    visalia: 'Visalia',
    thornton: 'Thornton',
    torrance: 'Torrance',
    fullerton: 'Fullerton',
    columbia: 'Columbia',
    lakewood: 'Lakewood',
    new_Haven: 'New Haven',
    hampton: 'Hampton',
    miramar: 'Miramar',
    victorville: 'Victorville',
    warren: 'Warren',
    west_Valley_City: 'West Valley City',
    cedar_Rapids: 'Cedar Rapids',
    stamford: 'Stamford',
    orange: 'Orange',
    dayton: 'Dayton',
    midland: 'Midland',
    kent: 'Kent',
    elizabeth: 'Elizabeth',
    pasadena: 'Pasadena',
    carrollton: 'Carrollton',
    coral_Springs: 'Coral Springs',
    sterling_Heights: 'Sterling Heights',
    fargo: 'Fargo',
    lewisville: 'Lewisville',
    meridian: 'Meridian',
    norman: 'Norman',
    palm_Bay: 'Palm Bay',
    athens: 'Athens',
    columbia: 'Columbia',
    abilene: 'Abilene',
    pearland: 'Pearland',
    santa_Clara: 'Santa Clara',
    round_Rock: 'Round Rock',
    topeka: 'Topeka',
    allentown: 'Allentown',
    clovis: 'Clovis',
    simi_Valley: 'Simi Valley',
    college_Station: 'College Station',
    thousand_Oaks: 'Thousand Oaks',
    vallejo: 'Vallejo',
    concord: 'Concord',
    rochester: 'Rochester',
    arvada: 'Arvada',
    lafayette: 'Lafayette',
    independence: 'Independence',
    west_Palm_Beach: 'West Palm Beach',
    hartford: 'Hartford',
    wilmington: 'Wilmington',
    lakeland: 'Lakeland',
    billings: 'Billings',
    ann_Arbor: 'Ann Arbor',
    fairfield: 'Fairfield',
    berkeley: 'Berkeley',
    richardson: 'Richardson',
    north_Charleston: 'North Charleston',
    cambridge: 'Cambridge',
    broken_Arrow: 'Broken Arrow',
    clearwater: 'Clearwater',
    west_Jordan: 'West Jordan',
    evansville: 'Evansville',
    league_City: 'League City',
    antioch: 'Antioch',
    manchester: 'Manchester',
    high_Point: 'High Point',
    waterbury: 'Waterbury',
    westminster: 'Westminster',
    richmond: 'Richmond',
    carlsbad: 'Carlsbad',
    las_Cruces: 'Las Cruces',
    murrieta: 'Murrieta',
    lowell: 'Lowell',
    provo: 'Provo',
    springfield: 'Springfield',
    elgin: 'Elgin',
    odessa: 'Odessa',
    lansing: 'Lansing',
    pompano_Beach: 'Pompano Beach',
    beaumont: 'Beaumont',
    temecula: 'Temecula',
    gresham: 'Gresham',
    allen: 'Allen',
    pueblo: 'Pueblo',
    everett: 'Everett',
    south_Fulton: 'South Fulton',
    peoria: 'Peoria',
    nampa: 'Nampa',
    tuscaloosa: 'Tuscaloosa',
    miami_Gardens: 'Miami Gardens',
    santa_Maria: 'Santa Maria',
    downey: 'Downey',
    concord: 'Concord',
    ventura: 'Ventura',
    costa_Mesa: 'Costa Mesa',
    sugar_Land: 'Sugar Land',
    menifee: 'Menifee',
    tyler: 'Tyler',
    sparks: 'Sparks',
    greeley: 'Greeley',
    rio_Rancho: 'Rio Rancho',
    sandy_Springs: 'Sandy Springs',
    dearborn: 'Dearborn',
    jurupa_Valley: 'Jurupa Valley',
    edison: 'Edison',
    spokane_Valley: 'Spokane Valley',
    hillsboro: 'Hillsboro',
    davie: 'Davie',
    green_Bay: 'Green Bay',
    centennial: 'Centennial',
    buckeye: 'Buckeye',
    boulder: 'Boulder',
    goodyear: 'Goodyear',
    el_Monte: 'El Monte',
    west_Covina: 'West Covina',
    brockton: 'Brockton',
    new_Braunfels: 'New Braunfels',
    el_Cajon: 'El Cajon',
    edinburg: 'Edinburg',
    renton: 'Renton',
    burbank: 'Burbank',
    inglewood: 'Inglewood',
    rialto: 'Rialto',
    lees_Summit: 'Lee\'s Summit',
    bend: 'Bend',
    woodbridge: 'Woodbridge',
    south_Bend: 'South Bend',
    wichita_Falls: 'Wichita Falls',
    st_George: 'Saint George',
    fishers: 'Fishers',
    carmel: 'Carmel',
    vacaville: 'Vacaville',
    quincy: 'Quincy',
    conroe: 'Conroe',
    chico: 'Chico',
    san_Mateo: 'San Mateo',
    lynn: 'Lynn',
    albany: 'Albany',
    hesperia: 'Hesperia',
    new_Bedford: 'New Bedford',
    davenport: 'Davenport',
    daly_City: 'Daly City',
    
    montevideo: 'Montevideo',
    salto: 'Salto',
    ciudad_de_la_Costa: 'Ciudad de la Costa',
    
    tashkent: 'Tachkent',
    andijan: 'Andijan',
    samarkand: 'Samarkand',
    
    port_Vila: 'Port-Vila',
    luganville: 'Luganville',
    norsup: 'Norsup',
    
    caracas: 'Caracas',
    maracaibo: 'Maracaibo',
    valencia: 'Valencia',
    
    ho_Chi_Minh_City: 'Ho Chi Minh-Ville',
    ha_Noi: 'Hanoï',
    hai_Phong: 'Haïphong',
    
    sanaa: 'Sanaa',
    al_Hudaydah: 'Al Hudaydah',
    ta_izz: 'Ta`izz',
    
    lusaka: 'Lusaka',
    kitwe: 'Kitwe',
    ndola: 'Ndola',
    kabwe: 'Kabwe',
    chingola: 'Chingola',
    mufulira: 'Mufulira',
    livingstone: 'Livingstone',
    luanshya: 'Luanshya',
    kasama: 'Kasama',
    chipata: 'Chipata',
    
    harare: 'Harare',
    bulawayo: 'Bulawayo',
    chitungwiza: 'Chitungwiza',
    mutare: 'Mutare',
    epworth: 'Epworth',
    gweru: 'Gweru',
    kwekwe: 'Kwekwe',
    kadoma: 'Kadoma',
    masvingo: 'Masvingo',
    chinhoyi: 'Chinhoyi',
    norton: 'Norton',
    marondera: 'Marondera',
    ruwa: 'Ruwa',

};

export default cities_fr;