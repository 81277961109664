import React from 'react'
import MainView from '../../views/Setting/MainView'

export default function SettingPage(props) {
    return (
            <MainView {...props}/>
    )
}


