import React from 'react'
import MainView from '../views/Favorite/MainView'

export default function FavoritePage(props) {
    return (
        <MainView {...props}/>
    )
}


