import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {  Link, NavLink, useHistory  } from 'react-router-dom';
import { LoadNotificationAction } from '../../store/actions/Notification/LoadNotificationAction';
import $ from "jquery";
import useOutsideClick from '../../helpers/useOutsideClick';
import { AdminLogOutAction } from '../../store/actions/Admin/AuthActions';
import { AdminAction } from '../../store/actions/Admin/AdminActions';
import { useTranslation } from 'react-i18next';

function Header() {
    const history = useHistory();
    const dispatch = useDispatch();
    const ref = useRef();

    const [t] = useTranslation();

    const admin = useSelector(state => state.adminAuth.admin);
    const [showNotifications, setShowNotifications] = useState(false);
    const [display, setDisplay] = useState(false);

    const authResponse = useSelector(state => state.adminAuth.adminAuth);
    
    const [classe, setClasse] = useState();
    // const counter = useSelector(state => state.addednotification); 
    
    useEffect(() => {
        $(document).on("click", function(event){
            if(!$(event.target).closest(".Dadupa-Popup-DropDown").length){
                //$('.Dadupa-Popup-DropDown').removeClass('Dadupa-Popup-DropDown_Active');
            }
            if(!$(event.target).closest(".Dadupa-User").length){
                $('.Dadupa-Mini-Profile').removeClass('Mini-Profile-Active');
            }
            if(!$(event.target).closest(".Dadupa-Message-Popup").length){
                // $('.Dadupa-Msgs-Box').removeClass('Msgs-Box-Active');
            }
            if(!$(event.target).closest(".Dadupa-Alert-Popup").length){
                // $('.Dadupa-Notifs-Box').removeClass('Notifs-Box-Active');
            }
            if(!$(event.target).closest(".Messenger-PreferencesBTN").length){
                $('.Preferences-List').removeClass('Preferences-ListShow');
            }
            if(!$(event.target).closest(".Conversation-BTN").length){
                $('.ConversationOptions-List').removeClass('ConversationOptions-ListShow');
            }
        });
        
    });

    useEffect(() => {
        let data = {
            'url'   :   'admin'
        }
        let dataa = {
            'url'   :   'notification'
        }
        if(authResponse === ""){
            dispatch(AdminAction(data, history));
            dispatch(LoadNotificationAction(dataa)); 
        }
        // dispatch(AdminAction(data, history));
        authResponse?.new_notification ? setClasse('new-notif') : setClasse('')
    }, [dispatch])

    const userMenu = () => {
        $('.Dadupa-Mini-Profile').toggleClass('Mini-Profile-Active');
        $('.Dadupa-Msgs-Box').removeClass('Msgs-Box-Active');
        $('.Dadupa-Notifs-Box').removeClass('Notifs-Box-Active');
    }

    $(window).bind('scroll', function () {
        if ( $(window).scrollTop() > 70 ) {
            $('.Dadupa-Header').addClass('Dadupa-Header-Fixed');
        } else {
            $('.Dadupa-Header').removeClass('Dadupa-Header-Fixed');
        }
    });

    const openNotifications = () => {
        setShowNotifications(!showNotifications )

        let data = {
            user_id : localStorage.getItem('user_id'),
        }

        localStorage.setItem('notification', 0);
        setClasse('')
    };

    const addMenu = () => {
        setDisplay(!display); 
        $('.Dadupa-Popup-DropDown').toggleClass('Dadupa-Popup-DropDown_Active');
        $('.Dadupa-Msgs-Box').removeClass('Msgs-Box-Active');
        $('.Dadupa-Notifs-Box').removeClass('Notifs-Box-Active');
    }

    const handlelogOut = () => {
        dispatch(AdminLogOutAction(history));
    }

    useOutsideClick(ref, () => {
        setDisplay(false)
        setShowNotifications(false)
    });

    return (
        <div>
            <div className="Dadupa-Overlay">
                <button className="Dadupa-Close">
                    <i className="uil uil-multiply"></i>
                </button>
            </div>

            

                <header className="Dadupa-Header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-2 col-md-2">
                                <div className="left-nav">
                                    <img src="/assets/images/dadupa-brand-text.svg" alt="Dadupa Connect" className="Logo-Desktop" />
                                    <img src="/assets/images/dadupa-responsive.svg" alt="Dadupa Connect" className="Logo-Responsive" />
                                </div>
                            </div>
                            <div className="col-md-5 d-none d-lg-block">
                                <div className="center-nav">
                                    <ul className="Dadupa-Nav">
                                        <li className="Nav-Item"><NavLink activeClassName="Active-Nav" to={`/admin/dashboard`} className="Nav-Link"><i className="uil uil-lightbulb-alt"></i>{t('dashboard')} </NavLink></li>
                                        <li className="Nav-Item"><NavLink activeClassName="Active-Nav" to={`/admin/articles`} className="Nav-Link"><i className="uil uil-moneybag"></i>{t('articles')}  </NavLink></li>
                                        <li className="Nav-Item"><NavLink activeClassName="Active-Nav" to={`/admin/reclamations`} className="Nav-Link"><i className="uil uil-moneybag">{t('reclamations')}</i>  </NavLink></li>
                                        <li className="Nav-Item"><NavLink activeClassName="Active-Nav" to={`/admin/reports`} className="Nav-Link"><i className="uil uil-moneybag"></i>{t('report')} </NavLink></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-10 col-md-10 col-lg-5">
                                <div className="right-nav">
                                    <div className="New-Post" >
                                        <button className="Add-New" data-toggle="tooltip" data-placement="bottom" title="Add new"><i className="uil uil-plus"></i></button>
                                        {display && <div className="Dadupa-Popup-DropDown Dadupa-Popup-DropDown_Active" ref={ref}>
                                            <ul className="Mini-Profile-Items">
                                            <li className="Mini-Profile-Item"><Link to={`/project/create`} ><i className="uil uil-rocket"></i>{t('header.menu.project')}</Link></li>
                                            <li className="Mini-Profile-Item"><Link to={`/funder/create`} ><i className="uil uil-briefcase-alt"></i> {t('funder')}</Link></li>
                                            <li className="Mini-Profile-Item"><a href="new-accompagnateur-offer"><i className="uil uil-comment-alt-notes"></i>{t('header.menu.mentoring')}</a></li>
                                            </ul>
                                        </div>}
                                    </div>
                                    <div className="Dadupa-Notifications">
                                        <div className="Dadupa-Notifications-Items">
                                            {/* <div className="Dadupa-Notifications-Item Dadupa-Search">
                                                <form className="search">
                                                    <input type="search" name="search" placeholder="Search Dadupa" />
                                                    <button type="submit" name="submit"><i className="uil uil-search"></i></button>
                                                </form>
                                            </div> */}
                                            <div className=" Dadupa-Notifications-Item Dadupa-Alert-Popup">
                                                <button onClick={openNotifications} className="Dadupa-Alert" data-toggle="tooltip" data-placement="bottom" title="Notifications">
                                                    <span className={classe}></span><i className="uil uil-bell"></i>
                                                </button>
                                               {showNotifications && 
                                                    <div className="Dadupa-Notifs-Box Dadupa-Msgs-Box Msgs-Box-Active Notifs-Box-Active" ref={ref}>
                                                        <h3>{t('notifications')}</h3>
                                                        <div className={' Msgs-List'} >
                                                            {/* { usernotifications.notifications.map((notification, index) =>
                                                                <NotificationMenu notification={notification} key={index} />
                                                            )} */}
                                                            <div className="All-Messages-Row">
                                                                <Link to={`/notifications`} className="all-messages-button">{t('seeAll')} {t('notifications')}</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            {/* <div className="Dadupa-Notifications-Item Dadupa-Message-Popup">
                                                <button onClick={openMessages} className="Dadupa-Message" data-toggle="tooltip" data-placement="bottom" title="Messages"><span className="new-message"></span><i className="uil uil-envelope"></i></button>
                                                {showMessages && <Messages/>}
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="Dadupa-User" onClick={userMenu}>

                                    {
                                        admin  &&
                                    <>
                                        <ul className="Dadupa-User-Infos">
                                            <li className="profile-image"> 
                                                <img src="/assets/images/avatar.png" alt="avatar" />
                                            </li>
                                            {/* <li className="profile-name">
                                            <span className="">{userProfile.name}</span>
                                            </li> */}
                                            <li className="profile-arrow"><i className="uil uil-angle-down"></i></li>
                                        </ul> 
                                        <div className="Dadupa-Mini-Profile">
                                            <label className="Mini-Profile-Name">{admin.name}</label>
                                            <ul className="Mini-Profile-Items">
                                                <li className="Mini-Profile-Item"><Link to={`/admin/settings`}><i className="uil uil-setting"></i>{t('setting')} </Link></li>
                                                <li className="Mini-Profile-Item"><a href="#" onClick={handlelogOut}><i className="uil uil-exit"></i>{t('logout')} </a></li>
                                            </ul>
                                        </div>
                                    </>
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                
                    
        </div>
    )
}

export default Header;
