import React from 'react'
import Notifications from './Notifications'




export default function MainView(props) { 
    

    return (
        <>  
            <div className="Page-Wrapper Profile">
                <div className="container">
                        <Notifications />
                </div>
            </div>
        </>
    
           
        
    )
}
