
const finances = [
    {value : '2500', label : 'filter.secteur.2500'},
    {value : '10000', label : 'filter.secteur.10000'},
    {value : '25000', label : 'filter.secteur.25000'},
    {value : '40000', label : 'filter.secteur.40000'},
    {value : '55000', label : 'filter.secteur.55000'},
    {value : '70000', label : 'filter.secteur.70000'},
    {value : '85000', label : 'filter.secteur.85000'},
    {value : '100000', label : 'filter.secteur.100000'}
];

export default finances;