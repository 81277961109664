import React from 'react'
import MultiStepProjectForm from "../../../views/Project/MultiStepProjectForm";

export default function AddProjectPage(props) {

    return (
        <div>
            <MultiStepProjectForm {...props}/>
        </div>
    )
}


