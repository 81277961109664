import React, {useCallback, useEffect, useRef, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { LikeAction } from '../../../../store/actions/Like/LikeAction';
import AddComment from '../Comment/AddComment';
import ShowComment from '../Comment/ShowComment';
import { useTranslation } from 'react-i18next';

export default function PostFooter({ post }) {
    const [comments, SetComments] = useState(false);
    const [commentBox, SetCommentBox] = useState(false);
    const [initial, setInitial] = useState(true);
    const [like, setLike] = useState(false);
    const [likeCount, setLikeCount] = useState();
    const [classe, setClasse] = useState();
    const {t} = useTranslation();

    const commentss = useSelector(state => state.getComments);
    const counter = useSelector(state => state.addednotification); // c'est ca qui declanche l'ajout de comment socket a ne pas supprimer
    const dispatch = useDispatch();

    useEffect(() => {
      like ? setClasse('Dislike') : setClasse('Like');
      if (initial) {
        setLike(post.is_liked);
      }
      setLikeCount(post.likeCount);
    })
    
    const likeAAction = () => {
      setLike(!like);
      setInitial(false)
      const dataa = {
          action: "like",
          provider_id: post.id,
          provider: "post",
          type    : like?'dislike':'like',
      }
      setClasse('Dislike');
      dispatch(LikeAction(dataa, '/like', ''));        
    }

    const showComments = e =>  {
      SetComments(e)
    }

    const showCommentBox = e => {
      SetCommentBox(!commentBox)
      SetComments(!comments)
    }

    const observer = useRef()
    const refcomment = useCallback( node =>{
      SetCommentBox(!commentBox)
      SetComments(!comments)
    }, [observer])

  return (
        
      <div className="PostFooter">

      <div className="reactions-wrap">
        <div className="reactions-box">
          <div className="row">
            <div className="col-6 col-md-4 col-lg-6">
              <div className="reaction likes"><i className="dadupa-icon icon-clap"></i><span>{likeCount}</span></div>
              <div className="reaction views"><i className="uil uil-eye"></i> <span>1500</span></div>
            </div>
            <div className="col-6 col-md-8 col-lg-6 text-right">
              <div className="reaction comments" ref={refcomment} onClick={e => showComments(post.id)} ><span>{post.commentCount} {t('comments')}</span></div>
              {/* <div className="reaction shares"><span>380 Shares</span></div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="reactions-buttons">
        {/* <button className="reaction-button reaction-like" type="button" name="button">
          <img src="/assets/images/icons/dadupa-clap-green.svg" alt="like"/>
          Aimer
        </button> */}

        <button className={like ? 'reaction-button reaction-like post-liked' : 'reaction-button reaction-like'} 
        onClick={likeAAction} toggle="#password-field" type="button" name="button">
            <img src={like?"/assets/images/icons/dadupa-clap-green.svg":"/assets/images/icons/dadupa-clap.svg"} alt=""/>
            {like ? t('unclap') : t('clap')}
        </button>

        <button className="reaction-button reaction-comment" onClick={showCommentBox} type="button" name="button">
          <img src="/assets/images/icons/dadupa-comment.svg" alt="comment"/>
          {t(`comment`)}
        </button>
        <button className="reaction-button" type="button" name="button" data-toggle="modal" data-target="#SharingModal">
          <img src="/assets/images/icons/dadupa-share.svg" alt="share"/>
          {t(`partagez`)}
        </button>
      </div>
      <div id="Comments-Wrap" className="Comments-Wrap">
        <div className="Comments-Box">
          
          {commentBox &&            
              <AddComment post = {post} />                                       
          }
          {comments  &&
              <ShowComment  post = {post} />                 
          }
        </div>
      </div>
       
    </div>
    )
}
