import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import ItemForm from '../User/Auth/ItemForm';
import {useFormFields} from '../../helpers/hooksFormInput';
import HeaderLogo from "../../layout/Header/HeaderLogo";
import Footer from "../../layout/footer/footer";
import $ from "jquery";
import 'jquery-validation';
import { useTranslation } from 'react-i18next';
import Spinner from 'react-bootstrap/Spinner';
import { AdminLoginAction } from '../../store/actions/Admin/AuthActions';


export default function LoginView(props) {

    const { t } = useTranslation();
    const [is_loading, setIsLoading] = useState(false);


    if (localStorage.getItem('admin-token')) {
        props.props.history.push('/admin/dashboard');
    }
    const [fields, handleFieldChange] = useFormFields({
        email: "",
        password: ""
    });

    const [passwordShown, setPasswordShown] = useState(false);
    const TogglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };
    
    const dispatch = useDispatch();

    const authResponse = useSelector(state => state.adminAuth.user);

    const handleLogin = (e) => {
        if($("#form-login").valid()){
            setIsLoading(true);
            dispatch(AdminLoginAction(fields, props.props));
        }          
    }

    return (
        <div>
            <HeaderLogo/>

            <div className="Dadupa-Login">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-8 d-none d-sm-block d-md-none d-lg-block">
                            <div className="page-image">
                                <img src="/assets/images/login-illustration.svg" alt="Dadupa Connect"/>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-4">
                            <div className="form-wrapper">
                                { authResponse?.success === false &&
                                     <div id="authErr" className="alert alert-danger">{t('authResponse.error')}</div>
                                }
                                <form id="form-login" onSubmit={e => {e.preventDefault(); handleLogin(e)}} className="form-login">
                                    <h3 className="form-title">{t('welcomeDescriptionadmin')}!</h3>
                                    <div className="form-inputs">
                                        <div className="input-row">
                                            <ItemForm type="email" name="email" value={fields.email}
                                              onChange={handleFieldChange}
                                              placeholder="Type your email addresse"
                                              margin="normal"
                                              variant="outlined"
                                              id="email"
                                              className="wizard-required" 
                                              required/>
                                        </div>
                                        <div className="input-row">
                                            <ItemForm  id="password" name="password"
                                               placeholder={t('password')}
                                               className="input-password"
                                               type={passwordShown ? "text" : "password"}
                                               onChange={handleFieldChange}
                                               required/>
                                                <span toggle="#password-field" onClick={TogglePasswordVisiblity}
                                                      className="uil uil-eye field-icon toggle-password"></span>
                                        </div>
                                    </div>
                                    <div className="login-options">
                                        <div className="remember-me">
                                            <label className="container-checkbox">
                                                <input type="checkbox"/>
                                                    <span className="checkmark"></span>
                                                    <span> {t('remember_me')}</span>
                                            </label>
                                        </div>
                                        <div className="forgot-password"><a href="#!">{t('forget_password')}</a></div>
                                    </div>
                                    <div className="form-submit">
                                        <button type="submit"  name="submit"> 
                                        {t('login')} 
                                        {is_loading && <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        /> }
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>

        </div>
    )
}
