const countries_fr = {
    afghanistan:'Afghanistan',
    åland_Islands:'Îles Åland',
    albania:'Albanie',
    algeria:'Algérie',
    american_samoa: 'Samoa américaines',
    andorra: 'Andorre',
    angola: 'Angola',
    anguilla: 'Anguilla',
    antarctica: 'Antarctique',
    antigua_and_barbuda: 'Antigua-et-Barbuda',
    argentina: 'Argentine',
    armenia: 'Arménie',
    aruba: 'Aruba',
    australia: 'Australie',
    austria: 'Autriche',
    azerbaijan: 'Azerbaïdjan',
    bahamas:'Bahamas',
    bahrain:'Bahreïn',
    bangladesh: 'Bangladesh',
    barbados: 'Barbade',
    belarus: 'Biélorussie',
    belgium: 'Belgique',
    belize: 'Belize',
    benin: 'Bénin',
    bermuda: 'Bermudes',
    bhutan: 'Bhoutan',
    bolivia: 'Bolivie',
    bonaire: 'Bonaire',
    bosnia: 'Bosnie',
    botswana: 'Botswana',
    bouvet_Island: 'Île Bouvet',
    brazil: 'Brésil',
    british_Indian_Ocean_Territory: 'Territoire britannique de l\’océan Indien',
    brunei_Darussalam: 'Brunei Darussalam',
    bulgaria: 'Bulgarie',
    burkina_Faso: 'Burkina Faso',
    burundi: 'Burundi',
    cabo_Verde: 'Cap-Vert',
    cambodia: 'Cambodge',
    cameroon: 'Cameroun',
    canada: 'Canada',
    cayman_Islands: 'Îles Caïmans',
    central_African_Republic: 'République centrafricaine',
    chad: 'Tchad',
    chile: 'Chili',
    china: 'Chine',
    christmas_Island: 'Île Christmas',
    colombia: 'Colombie',
    comoros: 'Comores',
    congo: 'Congo',
    congo_Democratic_Republic_of_the: 'Congo, République démocratique',
    cook_Islands: 'Îles Cook',
    costa_Rica: 'Costa Rica',
    croatia: 'Croatie',
    cuba: 'Cuba',
    curaçao: 'Curaçao',
    cyprus: 'Chypre',
    czechia: 'Tchéquie',
    côte_dIvoire: 'Côte d\'Ivoire',
    denmark: 'Danemark',
    djibouti: 'Djibouti',
    dominica: 'Dominique',
    dominican_Republic: 'République dominicaine',
    ecuador: 'Équateur',
    egypt: 'Égypte',
    el_Salvador: 'Salvador',
    equatorial_Guinea: 'Guinée équatoriale',
    eritrea: 'Érythrée', 
    estonia: 'Estonie',
    eswatini: 'Eswatini',
    ethiopia: 'Éthiopie',
    falkland_Islands_Malvinas: 'Îles Falkland (Malvinas)',
    faroe_Islands: 'Îles Féroé',
    fiji: 'Fidji',
    finland: 'Finlande',
    france: 'France',
    french_Guiana: 'Guyane française',
    french_Polynesia: 'Polynésie française',
    french_Southern_Territories: 'Terres australes françaises',
    gabon: 'Gabon',
    gambia: 'Gambie',
    georgia: 'Géorgie',
    germany: 'Allemagne',
    ghana: 'Ghana',
    gibraltar: 'Gibraltar',
    greece: 'Grèce',
    greenland: 'Groenland',
    grenada: 'Grenade',
    guadeloupe: 'Guadeloupe',
    guam: 'Guam',
    guatemala: 'Guatemala',
    guernsey: 'Guernesey',
    guinea: 'Guinée',
    guinea_Bissau: 'Guinée-Bissau',
    guyana: 'Guyana',
    haiti: 'Haïti',
    heard_Island_and_McDonald_Islands: 'Île Heard et îles McDonald',
    holy_See: 'Saint-Siège',
    honduras: 'Honduras',
    hong_Kong: 'Hong Kong',
    hungary: 'Hongrie',
    iceland: 'Islande',
    india: 'Inde',
    indonesia: 'Indonésie',
    iran_Islamic_Republicof: 'Iran, République islamique',
    iraq: 'Irak',
    isle_of_Man: 'Île de Man',
    italy: 'Italie',
    jamaica: 'Jamaïque',
    japan: 'Japon',
    jersey: 'Jersey',
    jordan: 'Jordanie',
    kazakhstan: 'Kazakhstan',
    kenya: 'Kenya',
    kiribati: 'Kiribati',
    korea_Democratic_Peoples_Republic_of: 'Corée, République populaire démocratique',
    korea_Republic_of: 'Corée, République',
    kuwait: 'Koweït',
    kyrgyzstan: 'Kirghizistan',
    lao_Peoples_Democratic_Republic: 'République démocratique populaire lao',
    latvia: 'Lettonie',
    lebanon: 'Liban',
    lesotho: 'Lesotho',
    liberia: 'Libéria',
    libya: 'Libye',
    liechtenstein: 'Liechtenstein',
    lithuania: 'Lituanie',
    luxembourg: 'Luxembourg',
    macao: 'Macao',
    madagascar: 'Madagascar',
    malawi: 'Malawi',
    malaysia: 'Malaisie',
    maldives: 'Maldives',
    mali: 'Mali',
    malta: 'Malte',
    marshall_Islands: 'Îles Marshall',
    martinique: 'Martinique',
    mauritania: 'Mauritanie',
    mauritius: 'Maurice',
    mayotte: 'Mayotte',
    mexico: 'Mexique',
    micronesia_Federated_States_of: 'Micronésie, États fédérés',
    moldova_Republic_of: 'Moldavie, République',
    monaco: 'Monaco',
    mongolia: 'Mongolie',
    montenegro: 'Monténégro',
    montserrat: 'Montserrat',
    morocco: 'Maroc',
    mozambique: 'Mozambique',
    myanmar: 'Myanmar',
    namibia: 'Namibie',
    nauru: 'Nauru',
    nepal: 'Népal',
    netherlands: 'Pays-Bas',
    new_Caledonia: 'Nouvelle-Calédonie',
    new_Zealand: 'Nouvelle-Zélande',
    nicaragua: 'Nicaragua',
    niger: 'Niger',
    nigeria: 'Nigeria',
    niue: 'Niue',
    norfolk_Island: 'Île Norfolk',
    north_Macedonia: 'Macédoine du Nord',
    northern_Mariana_Islands: 'Îles Mariannes du Nord',
    norway: 'Norvège',
    oman: 'Oman',
    pakistan: 'Pakistan',
    palau: 'Palaos',
    palestine_State_of: 'Palestine, État',
    panama: 'Panama',
    papua_New_Guinea: 'Papouasie-Nouvelle-Guinée',
    paraguay: 'Paraguay',
    peru: 'Pérou',
    philippines: 'Philippines',
    pitcairn: 'Pitcairn',
    poland: 'Pologne',
    portugal: 'Portugal',
    puerto_Rico: 'Porto Rico',
    qatar: 'Qatar',
    romania: 'Roumanie',
    russian_Federation: 'Fédération de Russie',
    rwanda: 'Rwanda',
    réunion: 'Réunion',
    saint_Barthélemy: 'Saint-Barthélemy',
    saint_Helena_Ascension_and_Tristan_da_Cunha: 'Sainte-Hélène, Ascension et Tristan da Cunha',
    saint_Kitts_and_Nevis: 'Saint-Kitts-et-Nevis',
    saint_Lucia: 'Sainte-Lucie',
    saint_Martin: 'Saint-Martin (partie française)',
    saint_Pierre_and_Miquelon: 'Saint-Pierre-et-Miquelon',
    saint_Vincent_and_the_Grenadines: 'Saint-Vincent-et-les-Grenadines',
    samoa: 'Samoa',
    san_Marino: 'Saint-Marin',
    sao_Tome_and_Principe: 'Sao Tomé-et-Principe',
    saudi_Arabia: 'Arabie saoudite',
    senegal: 'Sénégal',
    serbia: 'Serbie',
    seychelles: 'Seychelles',
    sierra_Leone: 'Sierra Leone',
    singapore: 'Singapour',
    sint_Maarten: 'Saint-Martin (partie néerlandaise)',
    slovakia: 'Slovaquie',
    slovenia: 'Slovénie',
    solomon_Islands: 'Îles Salomon',
    somalia: 'Somalie',
    south_Africa: 'Afrique du Sud',
    south_Georgia_and_the_South_Sandwich_Islands: 'Géorgie du Sud-et-les îles Sandwich du Sud',
    south_Sudan: 'Soudan du Sud',
    spain: 'Espagne',
    sri_Lanka: 'Sri Lanka',
    sudan: 'Soudan',
    suriname: 'Suriname',
    svalbard_and_Jan_Mayen: 'Svalbard et Jan Mayen',
    sweden: 'Suède',
    switzerland: 'Suisse',
    syrian_Arab_Republic: 'République arabe syrienne',
    taiwan_Province_of_China: 'Taïwan, Province de Chine',
    tajikistan: 'Tadjikistan',
    tanzania_United_Republic_of: 'Tanzanie, République unie',
    thailand: 'Thaïlande',
    timor_Leste: 'Timor-Leste',
    togo: 'Togo',
    tokelau: 'Tokelau',
    tonga: 'Tonga',
    trinidad_and_Tobago: 'Trinité-et-Tobago',
    tunisia: 'Tunisie',
    turkey: 'Turquie',
    turkmenistan: 'Turkménistan',
    turks_and_Caicos_Islands: 'Îles Turques-et-Caïques',
    tuvalu: 'Tuvalu',
    uganda: 'Ouganda',
    ukraine: 'Ukraine',
    united_Arab_Emirates: 'Émirats arabes unis',
    united_Kingdom: 'Royaume-Uni',
    united_States_Minor_Outlying_Islands: 'Îles mineures éloignées des États-Unis',
    united_States: 'États-Unis',
    uruguay: 'Uruguay',
    uzbekistan: 'Ouzbékistan',
    vanuatu: 'Vanuatu',
    venezuela_Bolivarian_Republic_of: 'Venezuela, République bolivarienne',
    vietNam: 'Viet Nam',
    virgin_Islands_British: 'Îles Vierges britanniques',
    virgin_Islands_US: 'Îles Vierges des États-Unis',
    wallis_and_Futuna: 'Wallis-et-Futuna',
    yemen: 'Yémen',
    zambia: 'Zambie',
    zimbabwe: 'Zimbabwe',
}

export default countries_fr;