import React from 'react'
import PostView from '../../views/Profile/PostView';

export default function ProfilePage(props) {
    return (         
        <PostView  {...props}/>
    )
}


