import React from 'react';
import ResetPasswordView from '../../../views/User/Auth/ResetPasswordView';

function Resetpassword(props) {
  return (
    <div>
        <ResetPasswordView props={props} />
    </div>
  )
}

export default Resetpassword