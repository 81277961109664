import React  from 'react'
import MultiStepProjectFormFunder from './MultiStepProjectForm';

export default function CreateFunder({props}) {

    return (
        <div>
            <MultiStepProjectFormFunder {...props}/>
        </div>
    )
}