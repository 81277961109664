import React from 'react'
import MainView from '../../views/Profile/Offres/MainView'

export default function OffrePage(props) {
    return (
        <MainView {...props}/>
    )
}


