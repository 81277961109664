import React from 'react'
import DashboardView from '../../views/Admin/Dashboard/DashboardView'

export default function Dashboard(props) {
    return (
        <div>
            <DashboardView props={props}/>
        </div>
    )
}
