import React from 'react'
import MainView from '../views/Notification/MainView'

export default function NotificationPage(props) {
    return (
        <MainView {...props}/>
    )
}


