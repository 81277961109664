import React from 'react'
import LoginView from  '../../../views/User/Auth/LoginView'

export default function UserLogin(props) {
    return (
        <div>
            <LoginView props={props}/>
        </div>
    )
}
