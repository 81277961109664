import React from 'react'
import MultiStepMentorForm from '../../views/Mentor/MultiStepMentorForm'

export default function AddMentorPage(props) {

    return (
        <div>
            <MultiStepMentorForm {...props}/>
        </div>
    )
}


