const countries_en = {
    afghanistan:'Afghanistan',
    åland_Islands:'Åland Islands',
    albania:'Albania',
    algeria:'Algeria',
    american_samoa: 'American Samoa',
    andorra: 'Andorra',
    angola: 'Angola',
    anguilla: 'Anguilla',
    antarctica: 'Antarctica',
    antigua_and_barbuda: 'Antigua and Barbuda',
    argentina: 'Argentina',
    armenia: 'Armenia',
    aruba: 'Aruba',
    australia: 'Australia',
    austria: 'Austria',
    azerbaijan: 'Azerbaijan',
    bahamas:'Bahamas',
    bahrain:'Bahrain',
    bangladesh: 'Bangladesh',
    barbados: 'Barbados',
    belarus: 'Belarus',
    belgium: 'Belgium',
    belize: 'Belize',
    benin: 'Benin',
    bermuda: 'Bermuda',
    bhutan: 'Bhutan',
    bolivia: 'Bolivia',
    bonaire: 'Bonaire',
    bosnia: 'Bosnia',
    botswana: 'Botswana',
    bouvet_Island: 'Bouvet Island',
    brazil: 'Brazil',
    british_Indian_Ocean_Territory: 'British Indian Ocean Territory',
    brunei_Darussalam: 'Brunei_Darussalam',
    bulgaria: 'Bulgaria',
    burkina_Faso: 'Burkina Faso',
    burundi: 'Burundi',
    cabo_Verde: 'Cabo Verde',
    cambodia: 'Cambodia',
    cameroon: 'Cameroon',
    canada: 'Canada',
    cayman_Islands: 'Cayman Islands',
    central_African_Republic: 'Central African Republic',
    chad: 'Chad',
    chile: 'Chile',
    china: 'China',
    christmas_Island: 'Christmas Island',
    colombia: 'Colombia',
    comoros: 'Comoros',
    congo: 'Congo',
    congo_Democratic_Republic_of_the: 'Congo, Democratic Republic',
    cook_Islands: 'Cook Islands',
    costa_Rica: 'Costa Rica',
    croatia: 'Croatia',
    cuba: 'Cuba',
    curaçao: 'Curaçao',
    cyprus: 'Cyprus',
    czechia: 'Czechia',
    côte_dIvoire: 'Côte dIvoire',
    denmark: 'Denmark',
    djibouti: 'Djibouti',
    dominica: 'Dominica',
    dominican_Republic: 'Dominican Republic',
    ecuador: 'Ecuador',
    egypt: 'Egypt',
    el_Salvador: 'El Salvador',
    equatorial_Guinea: 'Equatorial Guinea',
    eritrea: 'Eritrea',
    estonia: 'Estonia',
    eswatini: 'Eswatini',
    ethiopia: 'Ethiopia',
    falkland_Islands_Malvinas: 'Falkland Islands (Malvinas)',
    faroe_Islands: 'Faroe Islands',
    fiji: 'Fiji',
    finland: 'Finland',
    france: 'France',
    french_Guiana: 'French Guiana',
    french_Polynesia: 'French Polynesia',
    french_Southern_Territories: 'French Southern Territories',
    gabon: 'Gabon',
    gambia: 'Gambia',
    georgia: 'Georgia',
    germany: 'Germany',
    ghana: 'Ghana',
    gibraltar: 'Gibraltar',
    greece: 'Greece',
    greenland: 'Greenland',
    grenada: 'Grenada',
    guadeloupe: 'Guadeloupe',
    guam: 'Guam',
    guatemala: 'Guatemala',
    guernsey: 'Guernsey',
    guinea: 'Guinea',
    guinea_Bissau: 'Guinea-Bissau',
    guyana: 'Guyana',
    haiti: 'Haiti',
    heard_Island_and_McDonald_Islands: 'Heard Island and McDonald Islands',
    holy_See: 'Holy See',
    honduras: 'Honduras',
    hong_Kong: 'Hong Kong',
    hungary: 'Hungary',
    iceland: 'Iceland',
    india: 'India',
    indonesia: 'Indonesia',
    iran_Islamic_Republicof: 'Iran, Islamic Republic',
    iraq: 'Iraq',
    isle_of_Man: 'Isle of Man',
    italy: 'Italy',
    jamaica: 'Jamaica',
    japan: 'Japan',
    jersey: 'Jersey',
    jordan: 'Jordan',
    kazakhstan: 'Kazakhstan',
    kenya: 'Kenya',
    kiribati: 'Kiribati',
    korea_Democratic_Peoples_Republic_of: 'Korea, Democratic People\'s Republic',
    korea_Republic_of: 'Korea, Republic',
    kuwait: 'Kuwait',
    kyrgyzstan: 'Kyrgyzstan',
    lao_Peoples_Democratic_Republic: 'Lao People\'s Democratic Republic',
    latvia: 'Latvia',
    lebanon: 'Lebanon',
    lesotho: 'Lesotho',
    liberia: 'Liberia',
    libya: 'Libya',
    liechtenstein: 'Liechtenstein',
    lithuania: 'Lithuania',
    luxembourg: 'Luxembourg',
    macao: 'Macao',
    madagascar: 'Madagascar',
    malawi: 'Malawi',
    malaysia: 'Malaysia',
    maldives: 'Maldives',
    mali: 'Mali',
    malta: 'Malta',
    marshall_Islands: 'Marshall Islands',
    martinique: 'Martinique',
    mauritania: 'Mauritania',
    mauritius: 'Mauritius',
    mayotte: 'Mayotte',
    mexico: 'Mexico',
    micronesia_Federated_States_of: 'Micronesia, Federated States',
    moldova_Republic_of: 'Moldova, Republic',
    monaco: 'Monaco',
    mongolia: 'Mongolia',
    montenegro: 'Montenegro',
    montserrat: 'Montserrat',
    morocco: 'Morocco',
    mozambique: 'Mozambique',
    myanmar: 'Myanmar',
    namibia: 'Namibia',
    nauru: 'Nauru',
    nepal: 'Nepal',
    netherlands: 'Netherlands',
    new_Caledonia: 'New Caledonia',
    new_Zealand: 'New Zealand',
    nicaragua: 'Nicaragua',
    niger: 'Niger',
    nigeria: 'Nigeria',
    niue: 'Niue',
    norfolk_Island: 'Norfolk Island',
    north_Macedonia: 'North Macedonia',
    northern_Mariana_Islands: 'Northern Mariana Islands',
    norway: 'Norway',
    oman: 'Oman',
    pakistan: 'Pakistan',
    palau: 'Palau',
    palestine_State_of: 'Palestine, State',
    panama: 'Panama',
    papua_New_Guinea: 'Papua New Guinea',
    paraguay: 'Paraguay',
    peru: 'Peru',
    philippines: 'Philippines',
    pitcairn: 'Pitcairn',
    poland: 'Poland',
    portugal: 'Portugal',
    puerto_Rico: 'Puerto Rico',
    qatar: 'Qatar',
    romania: 'Romania',
    russian_Federation: 'Russian Federation',
    rwanda: 'Rwanda',
    réunion: 'Réunion',
    saint_Barthélemy: 'Saint Barthélemy',
    saint_Helena_Ascension_and_Tristan_da_Cunha: 'Saint Helena, Ascension and Tristan da Cunha',
    saint_Kitts_and_Nevis: 'Saint Kitts and Nevis',
    saint_Lucia: 'Saint Lucia',
    saint_Martin: 'Saint Martin (French part)',
    saint_Pierre_and_Miquelon: 'Saint Pierre and Miquelon',
    saint_Vincent_and_the_Grenadines: 'Saint Vincent and the Grenadines',
    samoa: 'Samoa',
    san_Marino: 'San Marino',
    sao_Tome_and_Principe: 'Sao Tome and Principe',
    saudi_Arabia: 'Saudi Arabia',
    senegal: 'Senegal',
    serbia: 'Serbia',
    seychelles: 'Seychelles',
    sierra_Leone: 'Sierra Leone',
    singapore: 'Singapore',
    sint_Maarten: 'Sint Maarten (Dutch part)',
    slovakia: 'Slovakia',
    slovenia: 'Slovenia',
    solomon_Islands: 'Solomon Islands',
    somalia: 'Somalia',
    south_Africa: 'South Africa',
    south_Georgia_and_the_South_Sandwich_Islands: 'South Georgia and the South Sandwich Islands',
    south_Sudan: 'South Sudan',
    spain: 'Spain',
    sri_Lanka: 'Sri Lanka',
    sudan: 'Sudan',
    suriname: 'Suriname',
    svalbard_and_Jan_Mayen: 'Svalbard and Jan Mayen',
    sweden: 'Sweden',
    switzerland: 'Switzerland',
    syrian_Arab_Republic: 'Syrian Arab Republic',
    taiwan_Province_of_China: 'Taiwan, Province of China',
    tajikistan: 'Tajikistan',
    tanzania_United_Republic_of: 'Tanzania, United Republic',
    thailand: 'Thailand',
    timor_Leste: 'Timor-Leste',
    togo: 'Togo',
    tokelau: 'Tokelau',
    tonga: 'Tonga',
    trinidad_and_Tobago: 'Trinidad and Tobago',
    tunisia: 'Tunisia',
    turkey: 'Turkey',
    turkmenistan: 'Turkmenistan',
    turks_and_Caicos_Islands: 'Turks and Caicos Islands',
    tuvalu: 'Tuvalu',
    uganda: 'Uganda',
    ukraine: 'Ukraine',
    united_Arab_Emirates: 'United Arab Emirates',
    united_Kingdom: 'United Kingdom',
    united_States_Minor_Outlying_Islands: 'United States Minor Outlying Islands',
    united_States: 'United States',
    uruguay: 'Uruguay',
    uzbekistan: 'Uzbekistan',
    vanuatu: 'Vanuatu',
    venezuela_Bolivarian_Republic_of: 'Venezuela, Bolivarian Republic',
    vietNam: 'Viet Nam',
    virgin_Islands_British: 'Virgin Islands, British',
    virgin_Islands_US: 'Virgin Islands, U.S.',
    wallis_and_Futuna: 'Wallis and Futuna',
    yemen: 'Yemen',
    zambia: 'Zambia',
    zimbabwe: 'Zimbabwe',
}
export default countries_en;