
const etatsCreate = [
    // ["", "filter.etat_projet"],
    ["idee", "filter.etat_projet.idee"],
    ["prototype", "filter.etat_projet.prototype"],
    ["mvp", "filter.etat_projet.mvp"],
    ["first_sale", "filter.etat_projet.first_sale"],
];


export default etatsCreate;