import React from 'react'
import LoginView from '../../views/Admin/LoginView'

export default function AdminLogin(props) {
    return (
        <div>
            <LoginView props={props}/>
        </div>
    )
}
