import React from 'react';
import ListFavoriteView from './ListFavoriteView';


export default function MainView() { 
    

    return (
        <div className="Page-Wrapper Profile">
            <div className="container">
                <ListFavoriteView />
            </div>
        </div>
    )
}
