import React, { useEffect, useState } from "react";
import { useSelector} from "react-redux";

import {
  LightgalleryProvider,
  LightgalleryItem,
} from "react-lightgallery";
import NoContent from "../../../utils/NoContent";


const PhotoItem = ({ image, thumb, group }) => (
  <div className="gallery-item">
    <LightgalleryItem group={group} src={image} thumb={thumb}>
      <img src={image} style={{ width: "100%" }} />
    </LightgalleryItem>
  </div>
);


export default function ShowFunderImgs() {

  const project = useSelector(state => state.funders.funder);

  const [imgs, setImgs] =  useState([]);

  useEffect (() => {
    if (project) { 
      const links = (Array.isArray(project.media)? project.media : [project?.media]).map((item) => { 
        if (/\.(jpeg|jpg|gif|png)$/i.test(item)) {
          return item;
        }
        return null;
      }).filter(item => item !== null);
      setImgs(links);
    }
  }, [project]);

  return (
    <div className="content">
      <LightgalleryProvider>
        {/* <h3>Photos</h3> */}

        <div className="photo-items-wrap">
          {imgs.length > 0 ? imgs.map((p, idx) => (
            <PhotoItem key={idx} image={p} group="imgs" />
          )): <NoContent/>
            }
        </div>
      </LightgalleryProvider>
    </div>
  );
}