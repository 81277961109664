import React from 'react'
import ResetPasswordWithToken from '../../../views/User/Auth/ResetPasswordWithToken'

function Resetpass(props) {
  return (
    <div>
        <ResetPasswordWithToken props={props}/>
    </div>
  )
}

export default Resetpass